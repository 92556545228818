import React, { useEffect, useState } from 'react';
import { Select, TitleCard, Button, FieldText } from '../../ui';
import { useForm } from 'react-hook-form';
import {
  Application,
  Candidate,
  CreateApplicationDto,
  CreateApplicationDurationDto,
  Job,
} from '../../../backend/careo-api';
import { UpdateCandidateVacancyContainer } from './update-candidate-vacancy-form.style';
import { AxiosInstance, AxiosInstanceErrorResponse } from '../../../utils';
import { toast } from 'react-toastify';
import { yupResolver } from '@hookform/resolvers/yup';
import { UpdateCandidateVacancySchema } from '../../../utils/validators/vacancy.validator';
import { MinusIcon, PlusIcon } from '../../../icons';
import { handleFormsServerErrors } from '../../../utils/utils';

type UpdateCandidateVacancyProps = {
  onCancel: () => void;
  onSuccess: () => void;
  candidates: Candidate[];
  selectedApplication: Application;
  applications: Application[];
  job: Job;
};

export const UpdateCandidateVacancy = ({
  onCancel,
  onSuccess,
  candidates,
  selectedApplication,
  job,
  applications,
}: UpdateCandidateVacancyProps) => {
  const {
    register,
    getValues,
    handleSubmit,
    setValue,
    formState: { errors },
    setError,
    setFocus,
  } = useForm<CreateApplicationDto>({
    resolver: yupResolver(UpdateCandidateVacancySchema as any),
  });

  const [durations, setDurations] = useState<CreateApplicationDurationDto[]>(
    [],
  );

  const onClickSubmit = () => {
    const values = getValues();

    AxiosInstance.applications
      .applicationsControllerUpdateDuration({
        ...values,
        jobId: job._id,
        candidateId: selectedApplication.candidate._id,
        status: selectedApplication.status,
      })
      .then(() => {
        toast.success('Candidate application updated successfully');
        onSuccess();
      })
      .catch((error: AxiosInstanceErrorResponse) => {
        handleFormsServerErrors(error, setError, setFocus);
        toast.error(error.message);
      });
  };

  const onClickAddDuration = () => {
    setDurations((prev) => [...prev, {} as CreateApplicationDurationDto]);
  };

  const onClickDeleteDuration = (index: number) => {
    if (durations.length <= 1) {
      return;
    }
    setDurations(() => {
      const tempDurations = getValues('durations');
      tempDurations.splice(index, 1);
      setValue('durations', tempDurations);
      const durationErrors = errors?.durations ?? [];
      durationErrors.splice?.(index, 1);
      setError('durations', durationErrors as any);
      return [...tempDurations];
    });
  };

  useEffect(() => {
    setDurations(() => {
      const tempDurations = applications
        .filter(
          (el) =>
            el.candidate._id === selectedApplication.candidate._id &&
            el.status === selectedApplication.status,
        )
        .sort(
          (a, b) =>
            new Date(a.availableFrom).getTime() -
            new Date(b.availableFrom).getTime(),
        );
      setValue(
        'durations',
        tempDurations.map((el) => ({
          availableFrom: el.availableFrom?.split('T')?.[0],
          availableTo: el.availableTo?.split('T')?.[0],
        })),
      );
      return tempDurations;
    });
  }, [applications, selectedApplication]);

  return (
    <UpdateCandidateVacancyContainer>
      <Select
        options={candidates.map((el) => ({
          label: el.firstName + ' ' + el.lastName,
          value: el._id!,
        }))}
        placeholder="Option"
        label="Candidate"
        required
        register={register('candidateId', { required: true })}
        error={errors.candidateId}
        disabled={true}
        value={selectedApplication.candidate._id}
      />
      {durations.map((_, index) => (
        <div className="row" key={index}>
          <div className="col-md-5">
            <FieldText
              placeholder="Enter here ..."
              label="Start Date"
              required
              type="date"
              register={register(`durations.${index}.availableFrom`)}
              error={errors.durations?.[index]?.availableFrom}
              min={job.vacantFrom.split('T')[0]}
              max={job.vacantTo.split('T')[0]}
            />
          </div>
          <div className="col-md-5">
            <FieldText
              placeholder="Enter here ..."
              label="End Date"
              required
              type="date"
              register={register(`durations.${index}.availableTo`)}
              error={errors.durations?.[index]?.availableTo}
              min={job.vacantFrom.split('T')[0]}
              max={job.vacantTo.split('T')[0]}
            />
          </div>
          <div className="col-md-2 d-flex align-items-center delete-icon">
            <Button
              type="danger"
              onClick={() => onClickDeleteDuration(index)}
              disabled={durations.length <= 1}
            >
              <MinusIcon />
            </Button>
          </div>
        </div>
      ))}

      <div className="add-duration-container d-flex justify-content-start">
        <Button type="success" onClick={onClickAddDuration}>
          <PlusIcon /> Add New Duration
        </Button>
      </div>

      <div className="form-actions">
        <Button onClick={onCancel}>Cancel</Button>
        <Button type="primary" onClick={handleSubmit(onClickSubmit)}>
          Update
        </Button>
      </div>
    </UpdateCandidateVacancyContainer>
  );
};
