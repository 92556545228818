import styled from 'styled-components';
import { Chevron } from '../../icons';
import { useEffect, useRef, useState } from 'react';

const Container = styled.div<{ showList: boolean }>`
  position: relative;

  .select-input {
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }

  .list-container {
    display: ${({ showList }) => (showList ? 'block' : 'none')};
    padding-top: 8px;
    padding-bottom: 8px;

    border-radius: 5px;
    position: absolute;
    min-height: 10px;
    width: 200%;
    box-shadow: 0px 5px 40px 0px rgba(0, 0, 0, 0.1);
    background: white;
    > div {
      padding-left: 8px;
      padding-right: 8px;
      cursor: pointer;

      &:hover {
        background: #8080801c;
      }
    }
  }
`;

type Props = {
  items: {
    label: string;
    value: string;
  }[];
  setSelectedItemValue: (value: string) => void;
  defaultValue?: string;
};

const Dropdown = ({ items, setSelectedItemValue, defaultValue }: Props) => {
  const [showList, setShowList] = useState(false);
  const [selectedItem, setSelectedItem] = useState(defaultValue);
  const divRef = useRef<any>(null);

  useEffect(() => {
    if (selectedItem) {
      setSelectedItemValue(selectedItem);
      setShowList(false);
    }
  }, [selectedItem]);

  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (divRef.current && !divRef.current.contains(event.target)) {
        setShowList(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <Container ref={divRef} showList={showList}>
      <div className="select-input" onClick={() => setShowList(!showList)}>
        <span>
          {items.find((i) => i.value === selectedItem)?.label ?? 'select'}
        </span>
        <Chevron direction={showList ? 'top' : 'bottom'} />
      </div>

      <div className="list-container">
        {items.map(({ label, value }, index) => (
          <div onClick={() => setSelectedItem(value)}>{label}</div>
        ))}
      </div>
    </Container>
  );
};

export default Dropdown;
