import React, { useEffect, useRef, useState } from 'react';
import { MessageContentContainer } from './messages-content.style';
import {
  AttachFileIcon,
  DotsIcon,
  DownloadIcon,
  RightArrowIcon,
} from '../../../icons';
import { ERoute } from '../../../utils';
import { SubTitlePage, Textarea, TitlePage, UserIcon } from '../../ui';
import { Candidate, Client, MEMBER_TYPE } from '../../../backend/careo-api';
import { WhatsappMessageUI } from '../../../pages/messages';
import { downloadFile } from '../../../utils/utils';

type MessageContentProps = {
  memberType: MEMBER_TYPE;
  selectedMember?: Candidate | Client;
  listMessages: WhatsappMessageUI[];
  sendMessageAndAttachmentWhatsapp: (
    phoneNumber: string,
    message: string,
    attachment?: File,
  ) => void;
  isLoading: boolean;
};

export const MessageContent = ({
  selectedMember,
  listMessages,
  sendMessageAndAttachmentWhatsapp,
  memberType,
  isLoading,
}: MessageContentProps) => {
  const messagesEndRef = useRef<any>(null);

  const [message, setMessage] = useState('');
  const [attachment, setAttachment] = useState<File | null>(null);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const fileInputTitleRef = useRef<HTMLInputElement>(null);

  const onMessageChange = (e: any) => {
    setMessage(e.target.value ?? '');
  };

  const submitMessage = () => {
    if (attachment) {
      if (attachment.size > +process.env.MAXIMUM_FILE_SIZE! * 1024 * 1024) {
        alert(`File size is bigger than ${process.env.MAXIMUM_FILE_SIZE} MB!`);
        return;
      }
      if (fileInputRef.current) fileInputRef.current.value = '';
      if (fileInputTitleRef.current) fileInputTitleRef.current.innerText = '';
    }
    sendMessageAndAttachmentWhatsapp(
      selectedMember?._id ?? '',
      message ?? '',
      attachment ?? undefined,
    );
    setAttachment(null);
    setMessage('');
  };

  const scrollToBottom = () => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  useEffect(() => {
    scrollToBottom();
  }, [listMessages]);

  if (!selectedMember) {
    return (
      <MessageContentContainer>
        <div className="no-conversation">
          <img src="/assets/img/no-item.png" width={200} />
          <br />
          <TitlePage>No conversation selected</TitlePage>
          <SubTitlePage>
            Please select an item on the left to view.
          </SubTitlePage>
        </div>
      </MessageContentContainer>
    );
  }

  return (
    <MessageContentContainer className="message-data">
      <div className="header-conversation">
        <div className="profile-image">
          <a
            href={`/${
              memberType === MEMBER_TYPE.Candidate
                ? ERoute.CANDIDATES
                : ERoute.CLIENTS
            }/${selectedMember._id}`}
            target="_blank"
            rel="noreferrer"
          >
            <UserIcon
              firstName={selectedMember.firstName}
              lastName={selectedMember.lastName}
              entity={memberType}
            />
          </a>
        </div>
        <div className="info-messenger">
          <a
            href={`/${
              memberType === MEMBER_TYPE.Candidate
                ? ERoute.CANDIDATES
                : ERoute.CLIENTS
            }/${selectedMember._id}`}
            target="_blank"
            rel="noreferrer"
          >
            <div className="name">
              {selectedMember
                ? selectedMember.firstName + ' ' + selectedMember.lastName
                : '-'}
            </div>
          </a>
          {/* <div className="status">
            Last message : '-'
          </div> */}
        </div>
        <div className="more-options">
          <DotsIcon />
        </div>
      </div>
      <div className="content-conversation">
        {isLoading ? (
          <MessageContentContainer>
            <div className="no-conversation">Loading ...</div>
          </MessageContentContainer>
        ) : (
          <>
            {listMessages.length ? (
              <>
                {listMessages.map((el) => {
                  const messageDiv = [];
                  if (el.message) {
                    messageDiv.push(
                      <div
                        className={`message-item ${
                          el.isReply ? 'reply' : 'sent'
                        }`}
                        key={el._id}
                        title={el.message}
                      >
                        <div>{el.message}</div>
                      </div>,
                    );
                  }

                  if (el.hostedFile) {
                    messageDiv.push(
                      <div
                        className={`message-item ${
                          el.isReply ? 'reply' : 'sent'
                        }`}
                        key={el._id}
                        title={el.hostedFile?.fileName}
                      >
                        <div>
                          {el.isLoading ? (
                            'attachment file is sending ...'
                          ) : (
                            <div
                              onClick={() => downloadFile(el.hostedFile!)}
                              className="file-downloadable"
                            >
                              <DownloadIcon /> {el.hostedFile?.fileName}
                            </div>
                          )}
                        </div>
                      </div>,
                    );
                  }
                  return messageDiv;
                })}
              </>
            ) : (
              <div className="no-conversation">No messages yet</div>
            )}
          </>
        )}
        <div ref={messagesEndRef} />
      </div>
      <div className="input-area">
        <div className="attach-button">
          <button
            style={{ background: 'none', border: 'none' }}
            onClick={() => fileInputRef.current?.click()}
          >
            <AttachFileIcon />
            <span ref={fileInputTitleRef} />
          </button>
          <input
            type="file"
            ref={fileInputRef}
            style={{ display: 'none' }}
            onChange={(event) => {
              if (event.target.files) {
                setAttachment(event.target.files[0]);
                fileInputTitleRef.current!.innerText =
                  event.target?.files?.[0]?.name ?? '';
              } else setAttachment(null);
            }}
          />
        </div>
        <div className="input-container">
          <Textarea
            placeholder="Write message here ..."
            value={message}
            onChange={onMessageChange}
            onKeyDown={(e: any) => {
              if (e.key === 'Enter' && !e.shiftKey) {
                e.preventDefault();
                submitMessage();
              }
            }}
          />
        </div>
        <div className="send-button" onClick={submitMessage}>
          <RightArrowIcon />
        </div>
      </div>
    </MessageContentContainer>
  );
};
