export enum ERoute {
  CLIENTS = 'clients',
  CANDIDATES = 'candidates',
  SHARED_CANDIDATES = 'shared-candidates',
  VACANCIES = 'vacancies',
  PAYROLLS = 'payrolls',
  PLACEMENTS = 'placements',
  CALENDAR = 'calendar',
  SETTING = 'settings',
  MESSAGES = 'messages',
}
