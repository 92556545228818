import styled from 'styled-components';
import { breakpoint } from '../../constants';

export const Table = styled.table`
  width: 100%;
  thead {
    padding: 50px;
    tr {
      th {
        font-size: 12px;
        color: #687588;
        height: 54px;
        background: #fafafa;
        padding: 10px 20px;
        &:first-child {
          border-radius: 10px 0 0 10px;
        }
        &:last-child {
          border-radius: 0 10px 10px 0;
        }
        div {
          display: flex;
          align-items: center;
          gap: 10px;
          label {
            flex: 1;
          }
        }

        &.action-th {
          text-align: end;
          width: 90px;
        }
      }
    }
  }
  tbody {
    tr {
      border-bottom: 1px solid #f1f2f4;
      td {
        padding: 18px 20px;
        color: #111827;
        font-size: 12px;
        .completion {
          display: flex;
          align-items: center;
          gap: 10px;
          .progress {
            width: 70px;
            height: 10px;
          }
        }
        .name-item {
          display: flex;
          align-items: center;
          gap: 10px;
          > div {
            .email {
              color: #a0aec0;
              font-size: 10px;
              font-weight: 400;
            }
          }
        }
        .action-item {
          display: flex;
          align-items: center;
          justify-content: flex-end;
          gap: 10px;
          > div {
            width: 30px;
            height: 30px;
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 10px;
            border-radius: 10px;
            cursor: pointer;
            svg {
              path {
                fill: white;
              }
            }
            &.view-icon {
              background-color: #27a376;
            }
            &.edit-icon {
              background-color: #5e7cad;
            }
            &.more-icon {
              background-color: #2f78ee;
            }
            &.download-icon {
              background-color: #2f50ee;
            }
            &.delete-icon {
              background-color: #e03137;
            }

            &:hover {
              opacity: 0.9;
            }
            &.disabled {
              cursor: not-allowed;
              color: gray;
              border: none;
              background-color: #e9ecef;
              svg > path {
                fill: #a0aec0;
              }
            }
          }
        }
        .task-container {
          display: flex;
          align-items: center;
          gap: 8px;
          cursor: pointer;
          &.disabled {
            color: #bcbcbc;
          }
        }
        .payroll-name-checkbox {
          display: flex;
          align-items: center;
          gap: 10px;
        }
      }
    }
  }
  .checkbox-table {
    padding: 10px;
    width: fit-content;
  }

  &.calendar {
    tbody {
      tr {
        td {
          padding: 16px;
          text-align: end;
          padding-bottom: 80px;
          /* cursor: pointer;
          &:hover {
            background-color: #11af6061;
          } */
        }
      }
    }
  }

  &.calendar-row {
    tbody {
      tr {
        td {
          padding: 0;
          border-right: 1px solid white;
          .day-container {
            height: 100px;
            min-width: 150px;
            position: relative;
            display: flex;
            flex-direction: column;
            .day-number {
              position: absolute;
              top: 10px;
              right: 10px;
            }
            .morning {
              flex: 1;
              border-radius: 10px;
            }
            .afternoon {
              border-radius: 10px;
              flex: 1;
            }
            .fullDay {
              border-radius: 10px;
              flex: 1;
            }
            &.available {
              .morning.active,
              .afternoon.active,
              .fullDay.active {
                background-color: #0caf60;
              }
            }
            &.booked {
              .morning.active,
              .afternoon.active,
              .fullDay.active {
                background-color: #bc9b5c;
              }
            }
          }
        }
      }
    }
  }

  @media (max-width: ${breakpoint.mobile}px) {
    thead {
      padding: 37px;
      tr {
        th {
          font-size: 8px;
          height: 40px;
          padding: 5px 10px;
          div {
            gap: 10px;
          }
          &.action-th {
            text-align: end;
            width: 90px;
          }
        }
      }
    }

    tbody {
      tr {
        td {
          padding: 12px 16px;
          font-size: 8px;
          .name-item {
            gap: 5px;
            > div {
              .email {
                font-size: 7px;
              }
            }
          }
          .action-item {
            gap: 5px;
            > div {
              width: 22px;
              height: 22px;
              gap: 5px;
              cursor: pointer;
              svg {
                width: 14px;
                height: 14px;
              }
            }
          }
          .task-container {
            gap: 5px;
          }
          .payroll-name-checkbox {
            gap: 5px;
          }
        }
      }
    }

    .checkbox-table {
      padding: 5px;
      width: fit-content;
    }

    &.calendar {
      tbody {
        tr {
          td {
            padding: 16px;
            text-align: end;
            padding-bottom: 80px;
          }
        }
      }
    }

    &.calendar-row {
      tbody {
        tr {
          td {
            padding: 0;
            .day-container {
              height: 50px;
              min-width: 80px;
            }
          }
        }
      }
    }
  }
`;
