import React from 'react';
import { Candidate } from '../../../backend/careo-api';
import { formatDate } from '../../../utils';
import { AddressDetails } from '../../address/address-details.component';

type CandidateDetailsGeneralProps = {
  candidate: Candidate;
};

export const CandidateDetailsGeneral = ({
  candidate,
}: CandidateDetailsGeneralProps) => {
  return (
    <>
      <div className="info-card">
        <div className="info-card-title">Personal Info</div>
        <hr />
        <div className="info-card-content row">
          <div className="col-md-6 row">
            <div className="info-item-title col-4">Full Name</div>
            <div className="info-item-data col-8">
              {candidate.firstName} {candidate.lastName}
            </div>
          </div>
          <div className="col-md-6 row">
            <div className="info-item-title col-4">Gender</div>
            <div className="info-item-data col-8">
              {candidate.gender || '-'}
            </div>
          </div>
          <div className="col-md-6 row">
            <div className="info-item-title col-4">Date of Birth</div>
            <div className="info-item-data col-8">
              {candidate.birthDate ? formatDate(candidate.birthDate) : '-'}
            </div>
          </div>
          <div className="col-md-6 row">
            <div className="info-item-title col-4">Nationality</div>
            <div className="info-item-data col-8">
              {candidate.nationality || '-'}
            </div>
          </div>
          <div className="col-md-6 row">
            <div className="info-item-title col-4">Email Address</div>
            <div className="info-item-data col-8">{candidate.email}</div>
          </div>
          <div className="col-md-6 row">
            <div className="info-item-title col-4">Designation</div>
            <div className="info-item-data col-8">
              {candidate.designation || '-'}
            </div>
          </div>
          <div className="col-md-6 row">
            <div className="info-item-title col-4">Grade</div>
            <div className="info-item-data col-8">{candidate.grade || '-'}</div>
          </div>
          <div className="col-md-6 row">
            <div className="info-item-title col-4">Department</div>
            <div className="info-item-data col-8">
              {candidate.department || '-'}
            </div>
          </div>
          <div className="col-md-6 row">
            <div className="info-item-title col-4">
              Professional Registration Number
            </div>
            <div className="info-item-data col-8">
              {candidate.professionalRegistrationNumber || '-'}
            </div>
          </div>
          <div className="col-md-6 row">
            <div className="info-item-title col-4">
              Professional Registration Authority
            </div>
            <div className="info-item-data col-8">
              {candidate.professionalRegistrationAuthority || '-'}
            </div>
          </div>
          <div className="col-md-6 row">
            <div className="info-item-title col-4">Pay Rate</div>
            <div className="info-item-data col-8">
              {candidate.payAmount ? `£${candidate.payAmount}` : '-'}
            </div>
          </div>
        </div>
      </div>
      <AddressDetails address={candidate.address} />
    </>
  );
};
