import * as yup from 'yup';
import { isCRMApp } from '../../environment/app.type';

const candidateSchema = yup.object().shape({
  recruiterId: isCRMApp
    ? yup.string().required('Recruiter is required.')
    : yup.string().optional(),
  officerId: !isCRMApp
    ? yup.string().required('Officer is required.')
    : yup.string().optional(),

  address: yup
    .object()
    .shape({
      street: yup
        .string()
        .transform((value, originalValue) =>
          !originalValue ? undefined : value,
        ),
      city: yup
        .string()
        .matches(/^[a-zA-Z0-9\s'’\-]*$/, 'City is not valid')
        .transform((value, originalValue) =>
          !originalValue ? undefined : value,
        ),
      county: yup
        .string()
        .min(1, 'County is required')
        .matches(/^[a-zA-Z0-9\s'’\-]*$/, 'County is not valid')
        .transform((value, originalValue) =>
          !originalValue ? undefined : value,
        ),
      region: yup
        .string()
        .min(1, 'Region is required')
        .matches(/^[a-zA-Z0-9\s'’\-]*$/, 'Region is not valid')
        .transform((value, originalValue) =>
          !originalValue ? undefined : value,
        ),
      country: yup
        .string()
        .min(1, 'Country is required')
        .matches(/^[a-zA-Z0-9\s'’\-]*$/, 'Country is not valid')
        .transform((value, originalValue) =>
          !originalValue ? undefined : value,
        ),
      zip: yup
        .string()
        .matches(/^[a-zA-Z0-9\s'’\-]*$/, 'ZIP code is not valid')
        .transform((value, originalValue) =>
          !originalValue ? undefined : value,
        ),
    })
    .transform((value, originalValue) => (!originalValue ? undefined : value)),

  title: yup.string().required('Title is required.'),
  firstName: yup
    .string()
    .required('First name is required')
    .matches(
      /^[a-zA-Z\s-']*$/,
      'First Name must contain only alphanumeric characters, spaces , apostrophes and hyphens',
    ),
  lastName: yup
    .string()
    .required('Last name is required')
    .matches(
      /^[a-zA-Z\s-']*$/,
      'Last Name must contain only alphanumeric characters, spaces , apostrophes and hyphens',
    ),
  email: yup
    .string()
    .email('Invalid email address')
    .required('Email address is required'),
  phoneNumber: yup
    .string()
    .matches(
      /^\+\d+$/,
      'Phone number should start with + sign and contain only digits',
    )
    .min(9, 'should be between 9 and 20 chars')
    .max(20, 'should be between 9 and 20 chars')
    .required('Phone number is required.'),
  homePhoneNumber: yup
    .string()
    .transform((value, originalValue) => (!originalValue ? undefined : value))
    .matches(
      /^\+\d+$/,
      'Phone number should start with + sign and contain only digits',
    )
    .min(9, 'should be between 9 and 20 chars')
    .max(20, 'should be between 9 and 20 chars'),
  workPhoneNumber: yup
    .string()
    .transform((value, originalValue) => (!originalValue ? undefined : value))
    .matches(
      /^\+\d+$/,
      'Phone number should start with + sign and contain only digits',
    )
    .min(9, 'should be between 9 and 20 chars')
    .max(20, 'should be between 9 and 20 chars'),
  birthDate: yup
    .string()
    .transform((value, originalValue) => (!originalValue ? undefined : value)),
  nationality: yup
    .string()
    .transform((value, originalValue) => (!originalValue ? undefined : value)),
  gender: yup
    .string()
    .transform((value, originalValue) => (!originalValue ? undefined : value)),
  payAmount: yup
    .number()
    .transform((value, originalValue) =>
      !originalValue ? null : Number(value),
    )
    .typeError('Pay Amount must be a valid number.')
    .transform((value, originalValue) => (!originalValue ? undefined : value))
    .positive('Pay Amount must be a positive number'),
  niNumber: yup
    .string()
    .transform((value, originalValue) => (!originalValue ? undefined : value))
    .matches(
      /^[a-zA-Z0-9\s]*$/,
      'National Insurance number must contain only alphanumeric characters',
    ),
  hiredStatus: yup.string(),
  department: yup
    .string()
    .transform((value, originalValue) => (!originalValue ? undefined : value))
    .matches(
      /^[a-zA-Z0-9\s]*$/,
      'Department must contain only alphanumeric characters',
    ),
  designation: yup
    .string()
    .transform((value, originalValue) => (!originalValue ? undefined : value)),
  currentlyEmployed: yup
    .string()
    .transform((value, originalValue) => (!originalValue ? undefined : value)),
  drivingLicence: yup.boolean(),
  ownCar: yup.boolean(),
  payrollProviderId: yup
    .string()
    .transform((value, originalValue) => (!originalValue ? undefined : value)),
  grade: yup
    .string()
    .transform((value, originalValue) => (!originalValue ? undefined : value)),
});

export { candidateSchema };
