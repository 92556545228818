import React, { useState } from 'react';
import { Button, ConfirmModal, SideModal, TitleCard } from '../ui';
import { DeleteIcon } from '../../icons';
import { NewDepartmentForm } from './new-department-form.component';
import { Client, Department } from '../../backend/careo-api';
import { AxiosInstance } from '../../utils';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

type ClientDepartmentProps = {
  client: Client;
  getClientDetails: () => void;
};

export const ClientDepartments = ({
  client,
  getClientDetails,
}: ClientDepartmentProps) => {
  const { id } = useParams();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [recordToDelete, setRecordToDelete] = useState<Department>();

  const deleteDepartment = () => {
    AxiosInstance.clients
      .clientsControllerDeleteDepartment(id!, recordToDelete?._id!)
      .then((response) => {
        getClientDetails();
        toast.success('Department removed successfully');
      })
      .catch(() => {
        toast.error('Something went wrong');
      });
  };

  return (
    <>
      <div className="info-card">
        <div className="info-card-header">
          <div className="info-card-title">Departments List</div>
          <Button type="primary" onClick={() => setIsModalOpen(true)}>
            Add new department
          </Button>
        </div>
        <hr />
        <div className="accordion" id="accordionPanelsStayOpenExample">
          {(client.departments ?? [])?.map((department, index) => {
            return (
              <div className="accordion-item" key={(department as any).any}>
                <h2 className="accordion-header" id="panelsStayOpen-headingOne">
                  <button
                    className="accordion-button"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target={`#panelsStayOpen-${index}`}
                    aria-expanded="true"
                    aria-controls={`panelsStayOpen-${index}`}
                  >
                    {department.departmentContact} #{index + 1}
                  </button>
                </h2>
                <div
                  id={`panelsStayOpen-${index}`}
                  className="accordion-collapse collapse show"
                  aria-labelledby="panelsStayOpen-headingOne"
                >
                  <div className="accordion-body">
                    <div className="info-card-content row mb-5">
                      <div className="col-md-12">
                        <TitleCard>Contact</TitleCard>
                      </div>
                      <div className="info-card-content row">
                        <div className="col-md-6 row">
                          <div className="info-item-title col-4">Title</div>
                          <div className="info-item-data col-8">
                            {department?.titleContact}
                          </div>
                        </div>
                        <div className="col-md-6 row">
                          <div className="info-item-title col-4">
                            First Name
                          </div>
                          <div className="info-item-data col-8">
                            {department?.firstNameContact}
                          </div>
                        </div>
                        <div className="col-md-6 row">
                          <div className="info-item-title col-4">Last Name</div>
                          <div className="info-item-data col-8">
                            {department?.lastNameContact}
                          </div>
                        </div>
                        <div className="col-md-6 row">
                          <div className="info-item-title col-4">Job Title</div>
                          <div className="info-item-data col-8">
                            {department?.jobTitleContact}
                          </div>
                        </div>
                        <div className="col-md-6 row">
                          <div className="info-item-title col-4">
                            Email Address
                          </div>
                          <div className="info-item-data col-8">
                            {department?.emailContact}
                          </div>
                        </div>
                        <div className="col-md-6 row">
                          <div className="info-item-title col-4">
                            Mobile Number
                          </div>
                          <div className="info-item-data col-8">
                            {department?.phoneNumberContact}
                          </div>
                        </div>
                        <div className="col-md-6 row">
                          <div className="info-item-title col-4">
                            Work Phone Number
                          </div>
                          <div className="info-item-data col-8">
                            {department?.workPhoneNumberContact}
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6 row">
                        <div className="info-item-title col-4">Department</div>
                        <div className="info-item-data col-8">
                          {department?.departmentContact}
                        </div>
                      </div>
                    </div>
                    <div className="info-card-content row mb-5">
                      <div className="col-md-12">
                        <TitleCard>Account</TitleCard>
                      </div>
                      <div className="info-card-content row">
                        <div className="col-md-6 row">
                          <div className="info-item-title col-4">Title</div>
                          <div className="info-item-data col-8">
                            {department?.titleAccount}
                          </div>
                        </div>
                        <div className="col-md-6 row">
                          <div className="info-item-title col-4">
                            First Name
                          </div>
                          <div className="info-item-data col-8">
                            {department?.firstNameAccount}
                          </div>
                        </div>
                        <div className="col-md-6 row">
                          <div className="info-item-title col-4">Last Name</div>
                          <div className="info-item-data col-8">
                            {department?.lastNameAccount}
                          </div>
                        </div>
                        <div className="col-md-6 row">
                          <div className="info-item-title col-4">Job Title</div>
                          <div className="info-item-data col-8">
                            {department?.jobTitleAccount}
                          </div>
                        </div>
                        <div className="col-md-6 row">
                          <div className="info-item-title col-4">
                            Email Address
                          </div>
                          <div className="info-item-data col-8">
                            {department?.emailAccount}
                          </div>
                        </div>
                        <div className="col-md-6 row">
                          <div className="info-item-title col-4">
                            Mobile Number
                          </div>
                          <div className="info-item-data col-8">
                            {department?.phoneNumberAccount}
                          </div>
                        </div>
                        <div className="col-md-6 row">
                          <div className="info-item-title col-4">
                            Work Phone Number
                          </div>
                          <div className="info-item-data col-8">
                            {department?.workPhoneNumberAccount}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="info-card-content row mb-5">
                      <div className="col-md-12">
                        <TitleCard>Bank details</TitleCard>
                      </div>
                      <div className="col-md-6 row">
                        <div className="info-item-title col-4">Bank Name</div>
                        <div className="info-item-data col-8">
                          {department?.bank?.name}
                        </div>
                      </div>
                      <div className="col-md-6 row">
                        <div className="info-item-title col-4">
                          Account Name
                        </div>
                        <div className="info-item-data col-8">
                          {department?.bank?.accountName}
                        </div>
                      </div>
                      <div className="col-md-6 row">
                        <div className="info-item-title col-4">
                          Account Number
                        </div>
                        <div className="info-item-data col-8">
                          {department?.bank?.accountNumber}
                        </div>
                      </div>
                      <div className="col-md-6 row">
                        <div className="info-item-title col-4">Type</div>
                        <div className="info-item-data col-8">- </div>
                      </div>
                      <div className="col-md-6 row">
                        <div className="info-item-title col-4">Sort Code</div>
                        <div className="info-item-data col-8">
                          {department?.bank?.sortNumber}
                        </div>
                      </div>
                    </div>
                    <div className="info-card-content mb-5 d-flex justify-content-end">
                      <Button
                        type="danger"
                        onClick={() => setRecordToDelete(department)}
                      >
                        <DeleteIcon /> Delete Department
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
          {!client.departments.length && (
            <div className="text-center m-4">
              No departments added. Please try to add
            </div>
          )}
        </div>
      </div>

      <SideModal
        isOpen={isModalOpen}
        setIsOpen={setIsModalOpen}
        title="New Department"
      >
        <NewDepartmentForm
          getClientDetails={getClientDetails}
          setIsOpen={setIsModalOpen}
        />
      </SideModal>

      <ConfirmModal
        isOpen={!!recordToDelete}
        title="Delete Department"
        onNegativeBtnClick={() => setRecordToDelete(undefined)}
        onPositiveBtnClick={() => deleteDepartment()}
      >
        Do you want to delete department of
        <b>{recordToDelete?.firstNameAccount}</b> rate ?
      </ConfirmModal>
    </>
  );
};
