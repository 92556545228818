import { AxiosError, AxiosResponse } from 'axios';
import { getToken, removeToken } from './token.utils';
import { Api } from '../backend/careo-api';
import { toast } from 'react-toastify';

export interface AxiosInstanceErrorResponse {
  status: number;
  message: string;
}

const AxiosInstance = new Api({
  baseURL: process.env.REACT_APP_API_URL,
});

AxiosInstance.instance.interceptors.request.use(
  async (config) => {
    const token = getToken();
    if (token) config.headers.Authorization = `Bearer ${token}`;
    config.headers['Content-Type'] = 'application/json';
    config.headers['ngrok-skip-browser-warning'] = 'skip-browser-warning';
    return config;
  },
  (error: AxiosError) => {
    return Promise.reject(error);
  },
);

AxiosInstance.instance.interceptors.response.use(
  (response: AxiosResponse) =>
    new Promise((resolve, reject) => {
      resolve(response.data);
    }),
  (error: AxiosError) => {
    let errorResponse: AxiosInstanceErrorResponse;
    if (error?.response?.status === 400) {
      errorResponse = {
        status: 400,
        message:
          (error?.response?.data as any)?.message ??
          (error?.response?.data as any) ??
          'Something went wrong',
      };
    } else if (error?.response?.status === 401) {
      removeToken();
      errorResponse = {
        status: 401,
        message:
          'Your token has been expired. The page will reload in 3 seconds.',
      };
      if (window.location.pathname !== '/login') {
        setTimeout(() => {
          window.location.reload();
        }, 3000);
      }
    } else if (error?.response?.status === 403) {
      const messageError = 'Access denied! You are not authorized.';
      toast.error(messageError);
      errorResponse = {
        status: 400,
        message: messageError,
      };
    } else if (error?.response?.status === 429) {
      const messageError =
        'Too many requests, please try again later or contact admin.';
      toast.error(messageError);
      errorResponse = {
        status: 400,
        message: messageError,
      };
    } else if (error?.response?.status === 500) {
      errorResponse = {
        status: 500,
        message: 'Something went wrong',
      };
    } else {
      errorResponse = {
        status: error?.response?.status ?? 0,
        message: 'Unknown error',
      };
    }

    return new Promise((_, reject) => {
      reject(errorResponse);
    });
  },
);

export { AxiosInstance };
