import React, { Dispatch, SetStateAction } from 'react';
import { Select, FieldText, Button, FormContainer } from '../ui';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { useParams } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import { CreateRateClientDto } from '../../backend/careo-api';
import { jobLevelWithGrades, titles } from '../../constants';
import {
  AxiosInstance,
  AxiosInstanceErrorResponse,
  rateSchema,
} from '../../utils';

type NewRateFormProps = {
  getClientDetails: any;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
};

export const NewRateForm = ({
  setIsOpen,
  getClientDetails,
}: NewRateFormProps) => {
  const { id } = useParams();

  const {
    register,
    getValues,
    formState: { errors },
    watch,
    setValue,
    handleSubmit,
  } = useForm<CreateRateClientDto>({
    resolver: yupResolver(rateSchema as any),
  });

  const formValues = watch();

  const onClickClose = () => {
    setIsOpen(false);
  };

  const onClickSubmit = () => {
    const values = getValues();

    AxiosInstance.clients
      .clientsControllerAddRate(id!, {
        ...values,
      })
      .then(() => {
        toast.success('Rate added successfully');
        getClientDetails();
        onClickClose();
      })
      .catch((error: AxiosInstanceErrorResponse) => {
        toast.error(error.message);
      });
  };

  return (
    <FormContainer>
      <Select
        placeholder="Select job title"
        label="Job title"
        options={jobLevelWithGrades.map((el) => ({
          label: el.level,
          value: el.level,
        }))}
        register={register('level')}
        error={errors.level}
      />
      <Select
        placeholder="Select grade"
        label="Grade"
        options={
          jobLevelWithGrades
            .find((el) => el.level === formValues.level)
            ?.grades?.map((el) => ({ label: el, value: el })) ?? []
        }
        register={register('grade')}
        error={errors.grade}
        disabled={!formValues.level}
      />
      <FieldText
        placeholder="Enter here ..."
        label="Amount"
        type="number"
        required
        register={register('amount', { required: true })}
        error={errors.amount}
      />
      <div className="form-actions">
        <Button onClick={onClickClose}>Cancel</Button>
        <Button type="primary" onClick={handleSubmit(onClickSubmit)}>
          Create
        </Button>
      </div>
    </FormContainer>
  );
};
