import React, { useEffect, useRef, useState } from 'react';
import {
  ArrowLeftUTurnIcon,
  ArrowRightUTurnIcon,
  DeleteIcon,
  DotsIcon,
  DownloadIcon,
  EditIcon,
  LeftArrowIcon,
} from '../../icons';
import { AxiosInstance, formatDateTime } from '../../utils';
import { Button, UserIcon } from '../ui';
import {
  BOX_TYPE,
  EmailAttachment,
  EmailBody,
  HostedFile,
} from '../../backend/careo-api';
import { downloadBase64File, downloadFile } from '../../utils';
import { EmailContentContainer } from './emails-content.style';
import { EmailBodyUi } from '../../pages/emails';
import { EmailCreate } from './email-create.component';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { MessageContentContainer } from '../messages/messages-content/messages-content.style';

type EmailContentProps = {};

export const EmailContent = ({}: EmailContentProps) => {
  const navigate = useNavigate();

  const { emailId, emailType = '' } = useParams();

  const targetRef = useRef<any>(null);

  const [newEmailData, setNewEmailData] = useState<EmailBodyUi>();
  const [emailData, setEmailData] = useState<EmailBodyUi>();
  const [isLoading, setIsLoading] = useState(true);

  const getEmailById = async (id: string) => {
    setIsLoading(true);

    const emailRequest =
      emailType === BOX_TYPE.DRAFT
        ? AxiosInstance.emailsDrafts.emailDraftsControllerFetchDraftMessageById
        : AxiosInstance.emails.emailsControllerFetchEmailMessageById;

    await emailRequest(id)
      .then((response) => {
        const result = response as unknown as EmailBody;
        const isDraft = result.labels.includes(BOX_TYPE.DRAFT);
        const isSent = isDraft || result.labels.includes(BOX_TYPE.SENT);
        setEmailData({
          ...result,
          isDraft: isDraft,
          isSent: isSent,
        });
      })
      .catch((error) => {
        toast.error(error?.response?.emailData ?? 'Something went wrong');
      });

    setIsLoading(false);
  };

  const onClickReply = (data: EmailBodyUi) => {
    const isDraft = data.labels.includes(BOX_TYPE.DRAFT);

    setNewEmailData({
      from: '',
      to: data.isSent ? data.to : [data.from],
      subject: `RE: ${data.subject}`,
      bodyHtml: `
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <div>
          <div>--------------------------------------------------</div>
          <div>Sent: ${data.date}</div>
          <div>To: ${data?.from}</div>
          <div>Subject: ${data.subject}</div>
          <br />
          ${data.bodyHtml}
        </div>
      `,
      attachments: [],
      body: '',
      date: new Date().toJSON(),
      isRead: false,
      messageId: isDraft ? data.messageId : '',
      labels: [BOX_TYPE.SENT],
      isDraft,
      isSent: true,
    });
  };

  const onClickTransfer = (data: EmailBodyUi) => {
    setNewEmailData({
      from: '',
      to: [],
      subject: `TR: ${data.subject}`,
      bodyHtml: `
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <div>
          <div>--------------------------------------------------</div>
          <div>Sent: ${data.date}</div>
          <div>To: ${emailData?.from}</div>
          <div>Subject: ${data.subject}</div>
          <br />

          ${data.bodyHtml}
        </div>
      `,
      attachments: [],
      body: '',
      date: new Date().toJSON(),
      isRead: false,
      messageId: new Date().toJSON(),
      labels: [BOX_TYPE.SENT],
      isDraft: false,
      isSent: false,
    });
  };

  const onClickEdit = (data: EmailBodyUi) => {
    setNewEmailData({
      ...data,
    });
  };

  const onClickDelete = async (data: EmailBodyUi) => {
    const deleteEmailRequest = data.isDraft
      ? AxiosInstance.emailsDrafts.emailDraftsControllerDeleteDraftEmail
      : AxiosInstance.emails.emailsControllerDeleteEmail;

    await deleteEmailRequest(data.messageId)
      .then(() => {
        toast.success('Email deleted successfully');
        navigate(`/emails/${emailType}`);
      })
      .catch((error) => {
        toast.error(error?.response?.data ?? 'Something went wrong');
      });
  };

  useEffect(() => {
    if (newEmailData && targetRef.current) {
      targetRef?.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [newEmailData]);

  useEffect(() => {
    if (emailId) {
      getEmailById(emailId);
    }
  }, [emailId]);

  if (isLoading) {
    return (
      <MessageContentContainer style={{ height: '100%' }}>
        <div className="no-conversation">Loading ...</div>
      </MessageContentContainer>
    );
  }

  if (!emailData) {
    return (
      <MessageContentContainer style={{ height: '100%' }}>
        <div className="no-conversation">Email not found</div>
      </MessageContentContainer>
    );
  }

  return (
    <EmailContentContainer>
      {/* when we click on edit on draft emails we don't show the content */}
      {!(newEmailData && newEmailData.labels.includes(BOX_TYPE.DRAFT)) && (
        <div className="email-item-container">
          <div className="header-conversation">
            <div className="subject-header">
              <LeftArrowIcon onClick={() => navigate(-1)} />
              {emailData.subject}
            </div>
            <div className="details-header">
              <div className="left-header">
                <div className="sender-profile-image">
                  <UserIcon
                    firstName={emailData.isSent ? 'M' : emailData.from}
                    lastName={emailData.isSent ? 'E' : ''}
                    entity={emailData.isSent ? 'user' : 'candidate'}
                  />
                </div>
                <div className="sender-info-messenger">
                  <div className="sender-name">{emailData.from}</div>
                  <div className="receiver-name">
                    to: {emailData.to.join(', ')}
                  </div>
                </div>
              </div>
              <div className="right-header">
                <div className="time-receiving">
                  {formatDateTime(emailData.date)}
                </div>
                {(!emailData.labels.includes(BOX_TYPE.DRAFT) ||
                  !emailData.labels.includes(BOX_TYPE.TRASH)) && (
                  <>
                    <div
                      className="arrows"
                      title="reply"
                      onClick={() => onClickReply(emailData)}
                    >
                      <ArrowLeftUTurnIcon />
                    </div>
                    <div
                      className="arrows"
                      title="forward"
                      onClick={() => onClickTransfer(emailData)}
                    >
                      <ArrowRightUTurnIcon />
                    </div>{' '}
                  </>
                )}
                {!emailData.labels.includes(BOX_TYPE.TRASH) && (
                  <div
                    className="icons"
                    title="delete"
                    onClick={() => onClickDelete(emailData)}
                  >
                    <DeleteIcon />
                  </div>
                )}
                <div className="icons">
                  <DotsIcon />
                </div>
              </div>
            </div>
          </div>

          <div className="content-conversation">
            <div className={`email-item`} key={emailData.messageId}>
              <div className="email-attachment-file-container">
                {emailData?.attachments?.map((file, index) => {
                  if ((file as EmailAttachment).data) {
                    return (
                      <div
                        className="attachment-file"
                        onClick={() =>
                          downloadBase64File(
                            (file as EmailAttachment).data,
                            (file as EmailAttachment).filename,
                            (file as EmailAttachment).mimeType,
                          )
                        }
                        key={index}
                      >
                        <DownloadIcon /> {(file as EmailAttachment).filename}
                      </div>
                    );
                  } else {
                    return (
                      <div
                        className="attachment-file"
                        onClick={() => downloadFile(file as HostedFile)}
                        key={(file as HostedFile)._id}
                      >
                        <DownloadIcon /> {(file as HostedFile).fileName}
                      </div>
                    );
                  }
                })}
              </div>
              <div
                className="email-content"
                dangerouslySetInnerHTML={{ __html: emailData.bodyHtml }}
              />
            </div>
            {emailData.labels.includes(BOX_TYPE.TRASH) ? (
              <></>
            ) : emailData.labels.includes(BOX_TYPE.DRAFT) ? (
              <div className="actions-container">
                <Button
                  className="email-actions"
                  onClick={() => onClickEdit(emailData)}
                >
                  Edit <EditIcon />
                </Button>
              </div>
            ) : (
              <div className="actions-container">
                <Button
                  className="email-actions"
                  onClick={() => onClickReply(emailData)}
                >
                  <ArrowLeftUTurnIcon /> Reply
                </Button>
                <Button
                  className="email-actions"
                  onClick={() => onClickTransfer(emailData)}
                >
                  <ArrowRightUTurnIcon /> Forward
                </Button>
              </div>
            )}
          </div>
        </div>
      )}
      {newEmailData && (
        <EmailCreate
          onCancel={() => {
            setNewEmailData(undefined);
          }}
          data={newEmailData}
        />
      )}

      <div ref={targetRef} />
    </EmailContentContainer>
  );
};
