import { useNavigate, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import {
  CardContainer,
  Button,
  EmailContent,
  EmailCreate,
  EmailsTitleList,
  EmailsTypeList,
} from '../../components';
import { EmailsPageContainer } from './emails.style';
import {
  UserEmailsResponseDto,
  BOX_TYPE,
  EmailBody,
} from '../../backend/careo-api';
import { AxiosInstance } from '../../utils';
import { toast } from 'react-toastify';
import { PlusIcon } from '../../icons';
import { useAuth } from '../../contexts/auth.context';

type EmailsPageProps = {};

export enum EmailSender {
  Candidate = 'candidate',
  Client = 'client',
  User = 'user',
}

export interface EmailBodyUi extends EmailBody {
  isDraft: boolean;
  isSent: boolean;
}

export interface EmailsEmailUi extends UserEmailsResponseDto {
  emails: EmailBodyUi[];
  isLoading: boolean;
}

export const EmailsPage = ({}: EmailsPageProps) => {
  const { user } = useAuth();
  const { emailId, emailType } = useParams();
  const navigate = useNavigate();

  const [isCreateEmailOpen, setIsCreateEmailOpen] = useState(false);

  const clickLinkGmail = () => {
    AxiosInstance.emails
      .emailsControllerGetAuthUrl({ portal: process.env.REACT_APP_NAME! })
      .then((response: any) => {
        window.location.href = response.url;
      })
      .catch((error) => {
        toast.error(error?.response?.data ?? 'Something went wrong');
      });
  };

  useEffect(() => {
    // Check if emailType is a valid BOX_TYPE
    if (!Object.values(BOX_TYPE).includes(emailType as BOX_TYPE)) {
      navigate(`/emails/${BOX_TYPE.INBOX}`);
    }
  });

  if (!Object.values(BOX_TYPE).includes(emailType as BOX_TYPE)) {
    return <></>;
  }

  //  #################### EMAILS ####################

  if (!user?.emailLinked) {
    return (
      <EmailsPageContainer>
        <CardContainer className="email-not-linked">
          <div> Gmail is not linked</div>
          <div className="gmail-link" onClick={clickLinkGmail}>
            Click here to link your Gmail
          </div>
        </CardContainer>
      </EmailsPageContainer>
    );
  }

  return (
    <EmailsPageContainer>
      <div className="emails-header">
        <div className="left-filter"></div>
        <div className="right-filter">
          {/* <SearchInput
            placeholder={'Search'}
            onChange={(e) => setSearch(e.target.value ?? '')}
          /> */}
          <Button type="primary" onClick={() => setIsCreateEmailOpen(true)}>
            <PlusIcon /> New email
          </Button>
        </div>
      </div>
      <CardContainer className="emails-container">
        <div className="left-container">
          <EmailsTypeList />
        </div>
        <div className="right-container">
          {isCreateEmailOpen ? (
            <EmailCreate
              onCancel={() => {
                setIsCreateEmailOpen(false);
              }}
              data={undefined}
            />
          ) : emailId ? (
            <EmailContent />
          ) : (
            <EmailsTitleList />
          )}
        </div>
      </CardContainer>
    </EmailsPageContainer>
  );
};
