import React from 'react';
import styled from 'styled-components';
import { breakpoint } from '../../../constants';

export const AddCandidateVacancyContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
  .form-actions {
    display: flex;
    gap: 20px;
    justify-content: flex-end;
  }
  .row {
    .delete-button {
      display: flex;
      flex-direction: column;
      gap: 12px;
      label {
        visibility: hidden;
      }
    }
  }

  @media (max-width: ${breakpoint.mobile}px) {
    gap: 20px;
    .form-actions {
      gap: 5px;
    }
    .row {
      gap: 10px;
      .delete-button {
        gap: 12px;
        label {
          display: none;
        }
      }
    }
  }
`;
