import {
  Application,
  Candidate,
  CandidatesList,
  Client,
  ExtendedCandidate,
  Job,
  Payroll,
} from '../../backend/careo-api';

export type TFilterCandidate = {
  search: string;
  regions: string[];
  counties: string[];
  departments?: string[];
  levels: string[];
  grades: string[];
  completions: string[];
  status: string[];
};

export const completionOptions = [
  { label: '0 - 25%', value: 25 },
  { label: '26 - 50%', value: 50 },
  { label: '51 - 75%', value: 75 },
  { label: '76 - 100%', value: 100 },
];

export const complianceStatusOptions = [
  { label: 'In progress', value: 'IN_PROGRESS' },
  { label: 'Passed', value: 'PASSED' },
  { label: 'Expired', value: 'EXPIRED' },
  { label: 'Failed', value: 'FAILED' },
];

export const filterCandidates = (
  candidates: ExtendedCandidate[] | Candidate[] = [],
  filter: TFilterCandidate,
) => {
  const search = (filter?.search ?? '').toLocaleLowerCase();
  const filteredData = candidates?.filter((el) => {
    if (search) {
      const userName = el.recruiter?.firstName + ' ' + el.recruiter?.lastName;
      const candidateName = el.firstName + ' ' + el.lastName;
      if (
        !candidateName?.toLocaleLowerCase().includes(search) &&
        !el.email?.toLocaleLowerCase().includes(search) &&
        !userName?.toLocaleLowerCase().includes(search) &&
        !el.designation?.toLocaleLowerCase().includes(search) &&
        !el.department?.toLocaleLowerCase().includes(search) &&
        !el.grade?.toLocaleLowerCase().includes(search) &&
        !el.address?.county?.toLocaleLowerCase().includes(search) &&
        !el.address?.region?.toLocaleLowerCase().includes(search) &&
        !el.hiredStatus?.toLocaleLowerCase().includes(search)
      ) {
        return false;
      }
    }

    if (filter?.regions?.length) {
      if (!filter.regions.includes(el.address?.region ?? '')) {
        return false;
      }
    }

    if (filter.counties?.length) {
      if (!filter.counties.includes(el.address?.county ?? '')) {
        return false;
      }
    }

    if (filter.departments?.length) {
      if (!filter.departments.includes(el.department ?? '')) {
        return false;
      }
    }

    if (filter.grades?.length) {
      if (!filter.grades.includes(el.grade ?? '')) {
        return false;
      }
    }

    if (filter.levels?.length) {
      if (!filter.levels.includes(el.designation ?? '')) {
        return false;
      }
    }

    if (filter.status?.length) {
      if (
        !filter.status.includes(
          (el as ExtendedCandidate).complianceStatus ?? '',
        )
      ) {
        return false;
      }
    }

    if (filter.completions?.length) {
      const completionPercentage =
        (el as ExtendedCandidate).completionPercentage ?? 0;

      return filter.completions.some((completion) => {
        return (
          completionPercentage >= Number(completion) - 25 &&
          completionPercentage <= Number(completion)
        );
      });
    }

    return true;
  });

  return filteredData;
};

export const filterCandidatesLists = (
  candidateList: CandidatesList[] = [],
  filter: any,
) => {
  const search = (filter?.search ?? '').toLocaleLowerCase();
  const filteredData = candidateList?.filter((el) => {
    if (search) {
      const userName = el.user?.firstName + ' ' + el.user?.lastName;
      if (
        !el.name?.toLocaleLowerCase().includes(search) &&
        !userName?.toLocaleLowerCase().includes(search)
      ) {
        return false;
      }
    }

    return true;
  });

  return filteredData;
};

export type TFilterClient = {
  search: string;
  regions: string[];
  counties: string[];
};

export const filterClients = (
  clients: Client[] = [],
  filter: TFilterClient,
) => {
  const search = (filter?.search ?? '').toLocaleLowerCase();
  const filteredData = clients?.filter((el) => {
    if (search) {
      const userName = el.user?.firstName + ' ' + el.user?.lastName;
      const clientName = el.firstName + ' ' + el.lastName;
      if (
        !el.clientName?.toLocaleLowerCase().includes(search) &&
        !clientName?.toLocaleLowerCase().includes(search) &&
        !el.email?.toLocaleLowerCase().includes(search) &&
        !userName?.toLocaleLowerCase().includes(search) &&
        !el.address?.county?.toLocaleLowerCase().includes(search) &&
        !el.address?.region?.toLocaleLowerCase().includes(search)
      ) {
        return false;
      }
    }

    if (filter?.regions?.length) {
      if (!filter.regions.includes(el.address?.region ?? '')) {
        return false;
      }
    }

    if (filter.counties?.length) {
      if (!filter.counties.includes(el.address?.county ?? '')) {
        return false;
      }
    }
    return true;
  });

  return filteredData;
};

export type TFilterVacancy = {
  search: string;
  regions: string[];
  levels: string[];
  grades: string[];
};

export const filterVacancies = (jobs: Job[] = [], filter: TFilterVacancy) => {
  const search = (filter?.search ?? '').toLocaleLowerCase();
  const filteredData = jobs?.filter((el) => {
    if (search) {
      const userName = el.user?.firstName + ' ' + el.user?.lastName;
      const clientName = el.client?.firstName + ' ' + el.client?.lastName;
      if (
        !el.specialty?.toLocaleLowerCase().includes(search) &&
        !el.grade?.toLocaleLowerCase().includes(search) &&
        !el.client.clientSiteName?.toLocaleLowerCase().includes(search) &&
        !clientName?.toLocaleLowerCase().includes(search) &&
        !el.client?.email?.toLocaleLowerCase().includes(search) &&
        !userName?.toLocaleLowerCase().includes(search) &&
        !el.status?.toLocaleLowerCase().includes(search) &&
        !el.level?.toLocaleLowerCase().includes(search) &&
        !el.client?.address?.region?.toLocaleLowerCase().includes(search) &&
        !el.level?.toLocaleLowerCase().includes(search)
      ) {
        return false;
      }
    }

    if (filter?.regions?.length) {
      if (!filter.regions.includes(el.client?.address?.region ?? '')) {
        return false;
      }
    }

    if (filter.grades?.length) {
      if (!filter.grades.includes(el.grade ?? '')) {
        return false;
      }
    }

    if (filter.levels?.length) {
      if (!filter.levels.includes(el.level ?? '')) {
        return false;
      }
    }

    return true;
  });

  return filteredData;
};

export type TFilterPlacement = {
  search: string;
  levels: string[];
  grades: string[];
};

export const filterPlacements = (
  placements: Application[] = [],
  filter: TFilterPlacement,
) => {
  const search = (filter?.search ?? '').toLocaleLowerCase();
  const filteredData = placements?.filter((el) => {
    if (search) {
      const clientName =
        el.job?.client?.firstName + ' ' + el.job?.client?.lastName;
      const candidateName =
        el.candidate?.firstName + ' ' + el.candidate?.lastName;
      if (
        !candidateName?.toLocaleLowerCase().includes(search) &&
        !el.candidate?.email?.toLocaleLowerCase().includes(search) &&
        !el.job?.grade?.toLocaleLowerCase().includes(search) &&
        !el.job?.specialty?.toLocaleLowerCase().includes(search) &&
        !el.job?.client?.clientName?.toLocaleLowerCase().includes(search) &&
        !clientName?.toLocaleLowerCase().includes(search) &&
        !el.job?.client?.email?.toLocaleLowerCase().includes(search)
      ) {
        return false;
      }
    }

    if (filter.grades?.length) {
      if (!filter.grades.includes(el.job?.grade ?? '')) {
        return false;
      }
    }

    if (filter.levels?.length) {
      if (!filter.levels.includes(el.job?.level ?? '')) {
        return false;
      }
    }

    return true;
  });

  return filteredData;
};

export type TFilterPayroll = {
  search: string;
};

export const filterPayrolls = (payrolls: Payroll[] = [], filter: any) => {
  const search = (filter?.search ?? '').toLocaleLowerCase();
  const filteredData = payrolls?.filter((el) => {
    if (search) {
      const leadContactName =
        el.leadContactFirstName + ' ' + el.leadContactLastName;
      const userName = el.user?.firstName + ' ' + el.user?.lastName;

      if (
        !el.payrollProviderName?.toLocaleLowerCase().includes(search) &&
        !leadContactName?.toLocaleLowerCase().includes(search) &&
        !userName?.toLocaleLowerCase().includes(search) &&
        !el.leadContactWorkPhoneNumber?.toLocaleLowerCase().includes(search) &&
        !el.vatNumber?.toLocaleLowerCase().includes(search) &&
        !el.leadContactEmail?.toLocaleLowerCase().includes(search)
      ) {
        return false;
      }
    }

    return true;
  });

  return filteredData;
};
