import React, { useEffect, useState } from 'react';
import { Alert, SubTitlePage, TitlePage } from '../../components';
import { Link } from 'react-router-dom';
import { TopStatisticsWidgets } from '../../components/dashboard/top-widgets';
import { AxiosInstance } from '../../utils';
import { DashboardStatistics } from '../../backend/careo-api';
import { CandidatesListPage } from '../candidate';
import ComplianceChart from '../../components/compliance/compliance-statistics/compliance-chart.component';
import DashboardComplianceChart from '../../components/dashboard/compliance-chart';
import styled from 'styled-components';
import DashboardRolesChart from '../../components/dashboard/roles-chart';
import Dropdown from '../../components/dropdown';
import * as moment from 'moment-timezone';
import { DashboardCandidatesList } from '../../components/candidate/dashboard-candidate-list/dashboard-candidate-list.component';
import { useAuth } from '../../contexts/auth.context';
const DashboardContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;

  .body {
    flex: 1;
    display: flex;
    flex-direction: row;
    gap: 24px;
  }
  .donut-charts {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
  .dashboad-subtitle {
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;
  }
`;

enum ETimePeriodsList {
  LastWeek = 'LastWeek',
  LastMonth = 'LastMonth',
  LastSixMonths = 'LastSixMonths',
  LastYear = 'LastYear',
  AllTime = 'AllTime',
}

const TimePeriodsList = [
  { value: 'LastWeek', label: 'Last Week' },
  { value: 'LastMonth', label: 'Last Month' },
  { value: 'LastSixMonths', label: 'Last 6 Months' },
  { value: 'LastYear', label: 'Last Year' },
  { value: 'AllTime', label: 'All Time' },
];

export const DashboardPage = () => {
  const { user } = useAuth();

  const [dateRange, setDateRange] = useState<{
    fromDate: string;
    toDate: string;
  }>({
    fromDate: '2024-09-10',
    toDate: '2024-09-16',
  });

  const [selectedItem, setSelectedItem] = useState<string>();

  const [statistics, setStatistics] = useState<DashboardStatistics>({
    dashboard: {
      _id: '',
      CandidatesStats: { newlyCreated: 0 },
      VacanciesStatistics: {
        newlyCreated: 0,
        hired: 0,
        pending: 0,
        active: 0,
        closed: 0,
        Filled: 0,
        Unfilled: 0,
      },
      ComplianceStats: { IN_PROGRESS: 0, PASSED: 0, FAILED: 0, EXPIRED: 0 },
    },
    liveStats: {
      incompleteProfiles: 0,
      placementsWithIncompleteProfiles: 0,
      clientsWithIncompleteProfiles: 0,
      payrollsWithIncompleteProfiles: 0,
    },
  });

  const complianceStats = statistics.dashboard.ComplianceStats;
  const rolesStats = statistics.dashboard.VacanciesStatistics;
  const {
    clientsWithIncompleteProfiles,
    incompleteProfiles,
    payrollsWithIncompleteProfiles,
    placementsWithIncompleteProfiles,
  } = statistics.liveStats;

  const fetchDashboardStatistics = async () => {
    const result =
      await AxiosInstance.statistics.statisticsControllerFetchDashboardStatistics(
        dateRange,
      );
    setStatistics(result as unknown as DashboardStatistics);
  };

  useEffect(() => {
    fetchDashboardStatistics();
  }, [dateRange.fromDate, dateRange.toDate]);

  useEffect(() => {
    switch (selectedItem) {
      case ETimePeriodsList.LastWeek:
        setDateRange({
          fromDate: moment
            .default(new Date())
            .subtract(1, 'week')
            .format('YYYY-MM-DD'),
          toDate: moment.default(new Date()).format('YYYY-MM-DD'),
        });
        break;

      case ETimePeriodsList.LastMonth:
        setDateRange({
          fromDate: moment
            .default(new Date())
            .subtract(1, 'month')
            .format('YYYY-MM-DD'),
          toDate: moment.default(new Date()).format('YYYY-MM-DD'),
        });
        break;

      case ETimePeriodsList.LastSixMonths:
        setDateRange({
          fromDate: moment
            .default(new Date())
            .subtract(6, 'month')
            .format('YYYY-MM-DD'),
          toDate: moment.default(new Date()).format('YYYY-MM-DD'),
        });
        break;

      case ETimePeriodsList.LastYear:
        setDateRange({
          fromDate: moment
            .default(new Date())
            .subtract(1, 'year')
            .format('YYYY-MM-DD'),
          toDate: moment.default(new Date()).format('YYYY-MM-DD'),
        });
        break;

      case ETimePeriodsList.AllTime:
        setDateRange({
          fromDate: moment
            .default(new Date())
            .subtract(10, 'year')
            .format('YYYY-MM-DD'),
          toDate: moment.default(new Date()).format('YYYY-MM-DD'),
        });
        break;

      default:
        setDateRange({
          fromDate: moment
            .default(new Date())
            .subtract(10, 'year')
            .format('YYYY-MM-DD'),
          toDate: moment.default(new Date()).format('YYYY-MM-DD'),
        });
        break;
    }
  }, [selectedItem]);

  return (
    <DashboardContainer>
      <div
        style={{
          display: 'flex',
          gap: '10px',
        }}
      >
        {incompleteProfiles > 0 && (
          <Alert
            type={'danger'}
            message={
              <>
                There are some candidates that are incomplete.
                <strong>
                  <Link to={'/candidates?isProfileComplete=false'}>
                    Click here
                  </Link>
                </strong>
              </>
            }
          />
        )}

        {payrollsWithIncompleteProfiles > 0 && (
          <Alert
            type={'danger'}
            message={
              <>
                There are some payrolls that are incomplete.
                <strong>
                  <Link to={'/payrolls?isProfileComplete=false'}>
                    Click here
                  </Link>
                </strong>
              </>
            }
          />
        )}

        {clientsWithIncompleteProfiles > 0 && (
          <Alert
            type={'danger'}
            message={
              <>
                There are some clients that are incomplete.
                <strong>
                  <Link to={'/clients?isProfileComplete=false'}>
                    Click here
                  </Link>
                </strong>
              </>
            }
          />
        )}

        {placementsWithIncompleteProfiles > 0 && (
          <Alert
            type={'danger'}
            message={
              <>
                There are some placements that are incomplete.{' '}
                <strong>
                  <Link to={'/placements?isProfileComplete=false'}>
                    Click here
                  </Link>
                </strong>
              </>
            }
          />
        )}
      </div>

      <div>
        <TitlePage>Hi, {user?.firstName}</TitlePage>
        <div className="dashboad-subtitle">
          <SubTitlePage>This is your HR report so far</SubTitlePage>
          <Dropdown
            items={TimePeriodsList}
            setSelectedItemValue={setSelectedItem}
            defaultValue="LastWeek"
          />
        </div>
      </div>

      <TopStatisticsWidgets statistics={statistics.dashboard} />

      <div className="body">
        <div
          style={{
            maxWidth: '75%',
          }}
        >
          <DashboardCandidatesList />
        </div>

        <div
          className="donut-charts"
          style={{
            maxWidth: '25%',
          }}
        >
          <DashboardComplianceChart
            data={[
              complianceStats.PASSED,
              complianceStats.IN_PROGRESS,
              complianceStats.FAILED,
            ]}
          />
          <DashboardRolesChart
            data={[rolesStats.Filled, rolesStats.pending, rolesStats.Unfilled]}
          />
        </div>
      </div>
    </DashboardContainer>
  );
};
