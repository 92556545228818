import * as yup from 'yup';

const departmentSchema = yup.object().shape({
  titleContact: yup.string().required('Client name is required'),
  firstNameContact: yup
    .string()
    .required('Client site name is required')
    .matches(
      /^[a-zA-Z0-9\s-]*$/,
      'First Name must contain only alphanumeric characters, spaces and hyphens',
    ),
  lastNameContact: yup
    .string()
    .required('Client site name is required')
    .matches(
      /^[a-zA-Z0-9\s-]*$/,
      'Last Name must contain only alphanumeric characters, spaces and hyphens',
    ),

  departmentContact: yup
    .string()
    .required('Client site is required')
    .matches(
      /^[a-zA-Z0-9\s-]*$/,
      'Department must contain only alphanumeric characters, spaces and hyphens',
    ),
  teamContact: yup
    .string()
    .required('Client site is required')
    .matches(
      /^[a-zA-Z0-9\s-]*$/,
      'Department must contain only alphanumeric characters, spaces and hyphens',
    ),
  jobTitleContact: yup
    .string()
    .required('Job title is required')
    .matches(
      /^[a-zA-Z0-9\s-]*$/,
      'Job title must contain only alphanumeric characters, spaces and hyphens',
    ),
  emailContact: yup
    .string()
    .email('Invalid email format')
    .required('Email is required'),
  phoneNumberContact: yup
    .string()
    .matches(
      /^\+\d+$/,
      'Phone number should start with + sign and contain only digits',
    )
    .min(9, 'should be between 9 and 20 chars')
    .max(20, 'should be between 9 and 20 chars')
    .required('Phone number is required.'),
  workPhoneNumberContact: yup
    .string()
    .matches(
      /^\+\d+$/,
      'Work Phone number should start with + sign and contain only digits',
    )
    .min(9, 'should be between 9 and 20 chars')
    .max(20, 'should be between 9 and 20 chars'),
  titleAccount: yup.string().required('Client name is required'),
  firstNameAccount: yup
    .string()
    .required('Client site name is required')
    .matches(
      /^[a-zA-Z0-9\s-]*$/,
      'First Name must contain only alphanumeric characters, spaces and hyphens',
    ),
  lastNameAccount: yup
    .string()
    .required('Client site name is required')
    .matches(
      /^[a-zA-Z0-9\s-]*$/,
      'Last Name must contain only alphanumeric characters, spaces and hyphens',
    ),
  jobTitleAccount: yup
    .string()
    .required('Job title is required')
    .matches(
      /^[a-zA-Z0-9\s-]*$/,
      'Job title must contain only alphanumeric characters, spaces and hyphens',
    ),
  emailAccount: yup
    .string()
    .email('Invalid email format')
    .required('Email is required'),
  phoneNumberAccount: yup
    .string()
    .matches(
      /^\+\d+$/,
      'Phone number should start with + sign and contain only digits',
    )
    .min(9, 'should be between 9 and 20 chars')
    .max(20, 'should be between 9 and 20 chars')
    .required('Phone number is required.'),
  workPhoneNumberAccount: yup
    .string()
    .matches(
      /^\+\d+$/,
      'Work Phone number should start with + sign and contain only digits',
    )
    .min(9, 'should be between 9 and 20 chars')
    .max(20, 'should be between 9 and 20 chars'),
  bank: yup.object().shape({
    name: yup
      .string()
      .matches(/^[a-zA-Z0-9\s'’\-]*$/, 'Bank name is not valid')
      .required('Bank name is required'),
    accountName: yup
      .string()
      .matches(/^[a-zA-Z0-9\s'’\-]*$/, 'Account name is not valid')
      .required('Account name is required'),
    accountNumber: yup.string().required('Account number is required'),
    sortNumber: yup.string().required('Sort code is required'),
  }),
});

export { departmentSchema };
