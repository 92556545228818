import styled from 'styled-components';

export const ConfirmModalContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 5;
  height: 100vh;
  @supports (height: 100dvh) {
    height: 100dvh;
  }
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 24px;

  .confirmation-container {
    display: flex;
    flex-direction: column;
    border-radius: 16px;
    padding: 24px;
    gap: 24px;
    background-color: white;
    width: 450px;
    max-width: 90%;

    .confirmation-header {
      display: flex;
      flex-shrink: 0;
      align-items: center;
      justify-content: space-between;
      border: unset;

      svg {
        cursor: pointer;
        border-radius: 100%;
        &:hover {
          box-shadow: rgba(50, 50, 105, 0.15) 0px 2px 5px 0px,
            rgba(0, 0, 0, 0.05) 0px 1px 1px 0px;
        }
      }
    }

    .confirmation-body {
      overflow-y: auto;
      color: #687588;
      b {
        color: #111827;
      }
    }

    .confirmation-footer {
      display: flex;
      justify-content: flex-end;
      gap: 24px;
      flex-wrap: wrap;
    }
  }

  &.availability {
    .confirmation-footer {
      gap: 5px;
      .full {
        background-color: #0caf60;
      }
      .day {
        background-color: #75d7ff;
      }
      .night {
        background-color: #191970;
      }
      &.disabled {
        background-color: gray !important;
      }
    }
  }
`;
