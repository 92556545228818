import React, { Dispatch, SetStateAction, useEffect } from 'react';
import { Select, TitleCard, FieldText, Button, FieldFile } from '../../ui';
import { titles } from '../../../constants';
import { useForm } from 'react-hook-form';
import { CreateDepartmentClientDto } from '../../../backend/careo-api';
import { NewDepartmentFormContainer } from './new-department-form.style';
import { AxiosInstance, AxiosInstanceErrorResponse } from '../../../utils';
import { toast } from 'react-toastify';
import { useParams } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import { departmentSchema } from '../../../utils/validators/department.validator';

type NewDepartmentFormProps = {
  getClientDetails: any;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
};

export const NewDepartmentForm = ({
  setIsOpen,
  getClientDetails,
}: NewDepartmentFormProps) => {
  const { id } = useParams();

  const {
    register,
    getValues,
    formState: { errors },
    watch,
    setValue,
    handleSubmit,
  } = useForm<CreateDepartmentClientDto>({
    resolver: yupResolver(departmentSchema as any),
  });

  const formValues = watch();

  const onClickClose = () => {
    setIsOpen(false);
  };

  const onClickSubmit = () => {
    const values = getValues();

    AxiosInstance.clients
      .clientsControllerAddDepartment(id!, {
        ...values,
      })
      .then(() => {
        toast.success('Department added successfully');
        getClientDetails();
        onClickClose();
      })
      .catch((error: AxiosInstanceErrorResponse) => {
        toast.error(error.message);
      });
  };

  useEffect(() => {
    console.log('errors');
    console.log(errors);
  }, [errors]);

  return (
    <NewDepartmentFormContainer>
      <>
        <TitleCard>Contact</TitleCard>
        <div className="row">
          <div className="col-md-2">
            <Select
              placeholder="Title"
              label="Title"
              required
              options={titles.map((el) => ({ label: el, value: el }))}
              register={register('titleContact')}
              error={errors.titleContact}
            />
          </div>
          <div className="col-md-5">
            <FieldText
              placeholder="Enter here ..."
              label="First Name"
              required
              register={register('firstNameContact', { required: true })}
              error={errors.firstNameContact}
            />
          </div>
          <div className="col-md-5">
            <FieldText
              placeholder="Enter here ..."
              label="Last Name"
              required
              register={register('lastNameContact', { required: true })}
              error={errors.lastNameContact}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-md-6">
            <FieldText
              placeholder="Enter here ..."
              label="Department"
              required
              register={register('departmentContact', { required: true })}
              error={errors.lastNameContact}
            />
          </div>
          <div className="col-md-6">
            <FieldText
              placeholder="Enter here ..."
              label="Team"
              required
              register={register('teamContact', { required: true })}
              error={errors.lastNameContact}
            />
          </div>
        </div>
        <FieldText
          placeholder="Enter here ..."
          label="Job title"
          required
          register={register('jobTitleContact', { required: true })}
          error={errors.lastNameContact}
        />
        <FieldText
          placeholder="Enter here ..."
          label="Email"
          required
          register={register('emailContact', { required: true })}
          error={errors.lastNameContact}
        />
        <div className="row">
          <div className="col-md-6">
            <FieldText
              type="phoneNumber"
              placeholder="+442071234567"
              label="Mobile Phone Number"
              required
              register={register('phoneNumberContact', { required: true })}
              error={errors.lastNameContact}
            />
          </div>
          <div className="col-md-6">
            <FieldText
              type="phoneNumber"
              placeholder="+442071234567"
              label="Work Phone Number"
              required
              register={register('workPhoneNumberContact', { required: true })}
              error={errors.workPhoneNumberContact}
            />
          </div>
        </div>
      </>
      <>
        <TitleCard>Account</TitleCard>
        <div className="row">
          <div className="col-md-2">
            <Select
              placeholder="Title"
              label="Title"
              required
              options={titles.map((el) => ({ label: el, value: el }))}
              register={register('titleAccount')}
              error={errors.titleAccount}
            />
          </div>
          <div className="col-md-5">
            <FieldText
              placeholder="Enter here ..."
              label="First Name"
              required
              register={register('firstNameAccount', { required: true })}
              error={errors.firstNameAccount}
            />
          </div>
          <div className="col-md-5">
            <FieldText
              placeholder="Enter here ..."
              label="Last Name"
              required
              register={register('lastNameAccount', { required: true })}
              error={errors.lastNameAccount}
            />
          </div>
        </div>

        <FieldText
          placeholder="Enter here ..."
          label="Job title"
          required
          register={register('jobTitleAccount', { required: true })}
          error={errors.jobTitleAccount}
        />
        <FieldText
          placeholder="Enter here ..."
          label="Email"
          required
          register={register('emailAccount', { required: true })}
          error={errors.emailAccount}
        />
        <div className="row">
          <div className="col-md-6">
            <FieldText
              type="phoneNumber"
              placeholder="+442071234567"
              label="Mobile Phone Number"
              required
              register={register('phoneNumberAccount', { required: true })}
              error={errors.phoneNumberAccount}
            />
          </div>
          <div className="col-md-6">
            <FieldText
              type="phoneNumber"
              placeholder="+442071234567"
              label="Work Phone Number"
              required
              register={register('workPhoneNumberAccount', { required: true })}
              error={errors.workPhoneNumberAccount}
            />
          </div>
        </div>
      </>
      <>
        <TitleCard>Bank details</TitleCard>
        <FieldText
          placeholder="Enter here ..."
          label="Bank Name"
          register={register('bank.name')}
          error={errors.bank?.name}
        />
        <FieldText
          placeholder="Enter here ..."
          label="Bank Account Name"
          register={register('bank.accountName')}
          error={errors.bank?.accountName}
        />
        <div className="row">
          <div className="col-md-6">
            <FieldText
              placeholder="Enter here ..."
              label="Sort Code"
              register={register('bank.sortNumber')}
              error={errors.bank?.sortNumber}
            />
          </div>
          <div className="col-md-6">
            <FieldText
              placeholder="Enter here ..."
              label="Account Number"
              register={register('bank.accountNumber')}
              error={errors.bank?.accountNumber}
            />
          </div>
        </div>
      </>
      <div className="form-actions">
        <Button onClick={onClickClose}>Cancel</Button>
        <Button type="primary" onClick={handleSubmit(onClickSubmit)}>
          Create
        </Button>
      </div>
    </NewDepartmentFormContainer>
  );
};
