import { IRoute } from '../types/routes.type';
import { PagesUrls } from './page-urls';
import { DashboardPage } from '../pages/dashboard';

export const DashboardRoutesCRM: IRoute[] = [
  {
    titleKey: PagesUrls.DASHBOARD.Index,
    path: PagesUrls.DASHBOARD.Index,
    element: <DashboardPage />,
    isVisibleOnSidebar: false,
  },
];
