import OfferCandidatePage from '../pages/offer/offer-candidate.page';
import OfferClientPage from '../pages/offer/offer-client.page';
import TimesheetCandidatePage from '../pages/timesheet/timesheet-candidate.page';
import TimesheetClientPage from '../pages/timesheet/timesheet-client.page';

import { IRoute } from '../types/routes.type';
import { PagesUrls } from './page-urls';

export const OffersRoutes: IRoute[] = [
  {
    path: PagesUrls.OFFER.Candidate,
    element: <OfferCandidatePage />,
    withoutLayout: true,
  },
  {
    path: PagesUrls.OFFER.Client,
    element: <OfferClientPage />,
    withoutLayout: true,
  },
];
