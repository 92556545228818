export { CandidateDetailsGeneral } from './candidate-details';
export { AddNewNoteCandidateForm } from './create-new-note-candidate-form/create-new-note-candidate-form.component';
export { ComplianceCandidatesList } from './compliance-candidate-list/compliance-candidate-list.component';
export { NewCandidateForm } from './new-candidate-form/new-candidate-form.component';
export { AddToVacancyForm } from './add-to-vancancy-form/add-to-vancancy-form.component';
export { UpdateCandidateForm } from './update-candidate-form/update-candidate-form.component';
export { ComplianceEdit } from '../compliance/compliance-edit/compliance-edit.component';
export { ComplianceList } from '../compliance/compliance-list/compliance-list.component';
export { SelectFileList } from './select-file/select-file-list.component';
export { AvailabilityComponent } from './availability/availability.component';
