import React from 'react';
import styled from 'styled-components';
import { breakpoint } from '../../constants';

const getTypeColor = (entity: 'user' | 'candidate' | 'client' | 'payroll') => {
  switch (entity) {
    case 'user':
      return '#0caf60'; // Green
    case 'candidate':
      return '#007bff'; // Blue
    case 'client':
      return '#ff6600'; // Orange
    default:
      return '#000000'; // Default to green if type is not recognized
  }
};

const UserIconContainer = styled.div<{ entity: string }>`
  text-transform: uppercase;
  border-radius: 50px;
  min-width: 40px;
  max-width: 40px;
  min-height: 40px;
  max-height: 40px;
  background-color: ${({ entity }: any) => getTypeColor(entity)};
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font-weight: bolder;

  @media (max-width: ${breakpoint.mobile}px) {
    min-width: 25px;
    max-width: 25px;
    min-height: 25px;
    max-height: 25px;
    font-size: 10px;
  }
`;

type UserIconProps = {
  firstName: string;
  lastName: string;
  entity: 'user' | 'candidate' | 'client' | 'payroll';
};

export const UserIcon = ({ firstName, lastName, entity }: UserIconProps) => {
  const getOnlyAlphabet = () => {
    return firstName?.[0] + lastName?.[0];
  };

  return (
    <UserIconContainer entity={entity}>{getOnlyAlphabet()}</UserIconContainer>
  );
};
