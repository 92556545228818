import React, { useEffect, useRef, useState } from 'react';
import { MessageContentContainer } from './messages-content.style';
import {
  AppIcon,
  ArrowLeftUTurnIcon,
  ArrowRightUTurnIcon,
  AttachFileIcon,
  CancelIcon,
  DeleteIcon,
  DotsIcon,
  DownloadIcon,
  EditIcon,
  PlusIcon,
  RightArrowIcon,
} from '../../../icons';
import { ERoute, formatDateTime } from '../../../utils';
import {
  Button,
  FieldText,
  Select,
  SubTitlePage,
  TextEditor,
  TitlePage,
  UserIcon,
} from '../../ui';
import { EmailsMessageUi } from '../../../pages/messages';
import {
  Candidate,
  Client,
  HostedFile,
  MEMBER_TYPE,
} from '../../../backend/careo-api';
import { downloadFile } from '../../../utils/utils';
import { EmailContentContainer } from './emails-content.style';
import { useAuth } from '../../../contexts/auth.context';

type EmailContentProps = {
  memberType: MEMBER_TYPE;
  selectedMember?: Candidate | Client;
  listEmails: EmailsMessageUi[];
  isLoading: boolean;
  sendEmail: (
    email: string,
    memberType: MEMBER_TYPE,
    subject: string,
    body: string,
    attachments: File[],
  ) => Promise<void>;
  draftEmail: (
    subject: string,
    body: string,
    attachments: File[],
  ) => Promise<void>;
  updateDraftEmail: (
    id: string,
    subject: string,
    body: string,
    attachments: File[],
    hostedFiles: HostedFile[],
  ) => Promise<void>;
  sendDraftEmail: (id: string) => Promise<void>;
  memberEmails: {
    firstName: string;
    lastName: string;
    email: string;
    memberType: MEMBER_TYPE;
  }[];
  deleteEmail: (id: string, isDraft: boolean) => Promise<void>;
};

export const EmailContent = ({
  selectedMember,
  listEmails,
  memberEmails,
  memberType,
  isLoading,
  sendEmail,
  draftEmail,
  updateDraftEmail,
  sendDraftEmail,
  deleteEmail,
}: EmailContentProps) => {
  const { user } = useAuth();

  const fileInputRef = useRef<HTMLInputElement>(null);

  const [isCreateEmailOpen, setIsCreateEmailOpen] = useState(false);
  const [selectedReceiver, setSelectedReceiver] = useState<{
    firstName: string;
    lastName: string;
    email: string;
    memberType: MEMBER_TYPE;
  }>();
  const [subject, setSubject] = useState('');
  const [body, setBody] = useState('');
  const [attachments, setAttachments] = useState<File[]>([]);
  const [hostedFiles, setHostedFiles] = useState<HostedFile[]>([]);
  const [updatedId, setUpdatedId] = useState('');
  const [isTransfer, setIsTransfer] = useState(false);

  const removeAttachment = (index: number) => {
    setAttachments((prev) => prev.filter((_, i) => i !== index));
  };

  const removeHostedFile = (id: string) => {
    setHostedFiles((prev) => prev.filter((el, i) => el._id !== id));
  };

  const onClickReply = (selectedEmail: EmailsMessageUi) => {
    const sender = user;
    const receiver = selectedEmail.member;

    setIsCreateEmailOpen(true);
    setSubject(`RE: ${selectedEmail.subject}`);
    setBody(`
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <div>
        <div>--------------------------------------------------</div>
        <div>From: ${sender?.firstName} ${sender?.lastName} &lt;${sender?.email}&gt;</div>
        <div>Sent: ${selectedEmail.timestamp}</div>
        <div>To: ${receiver?.firstName} ${receiver?.lastName} &lt;${receiver?.email}&gt;</div>
        <div>Subject: ${selectedEmail.subject}</div>
        <br />
        ${selectedEmail.body}
      </div>
    `);
  };

  const onClickTransfer = (selectedEmail: EmailsMessageUi) => {
    const sender = user;
    const receiver = selectedEmail.member;

    setIsCreateEmailOpen(true);
    setSubject(`TR: ${selectedEmail.subject}`);
    setBody(`
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <div>
        <div>--------------------------------------------------</div>
        <div>From: ${sender?.firstName} ${sender?.lastName} &lt;${sender?.email}&gt;</div>
        <div>Sent: ${selectedEmail.timestamp}</div>
        <div>To: ${receiver?.firstName} ${receiver?.lastName} &lt;${receiver?.email}&gt;</div>
        <div>Subject: ${selectedEmail.subject}</div>
        <br />
        ${selectedEmail.body}
      </div>
    `);
    setIsTransfer(true);
    setSelectedReceiver(undefined);
  };

  const onClickEdit = (selectedEmail: EmailsMessageUi) => {
    setIsCreateEmailOpen(true);
    setUpdatedId(selectedEmail._id);
    setSubject(selectedEmail.subject);
    setBody(selectedEmail.body);
    setHostedFiles(selectedEmail.hostedFiles);
    setAttachments([]);
  };

  const onClickDelete = (selectedEmail: EmailsMessageUi) => {
    deleteEmail(selectedEmail._id, selectedEmail.isDraft ?? false);
  };

  const clearData = () => {
    setIsCreateEmailOpen(false);
    setUpdatedId('');
    setSubject('');
    setBody('');
    setHostedFiles([]);
    setAttachments([]);
    setIsTransfer(false);
  };

  useEffect(() => {
    setIsCreateEmailOpen(false);
    setSelectedReceiver({
      firstName: selectedMember?.firstName ?? '',
      lastName: selectedMember?.lastName ?? '',
      email: selectedMember?.email ?? '',
      memberType: memberType,
    });
  }, [selectedMember]);

  if (!selectedMember) {
    return (
      <MessageContentContainer>
        <div className="no-conversation">
          <img src="/assets/img/no-item.png" width={200} />
          <br />
          <TitlePage>No email selected</TitlePage>
          <SubTitlePage>
            Please select an item on the left to view.
          </SubTitlePage>
        </div>
      </MessageContentContainer>
    );
  }

  if (isLoading) {
    return (
      <MessageContentContainer>
        <div className="no-conversation">Loading ...</div>
      </MessageContentContainer>
    );
  }

  if (isCreateEmailOpen) {
    return (
      <EmailContentContainer>
        <div className="create-email-container">
          <div className="to-input">
            To:{' '}
            {!isTransfer ? (
              <FieldText
                value={`${selectedReceiver?.firstName} ${selectedReceiver?.lastName} <${selectedReceiver?.email}>`}
                disabled
              />
            ) : (
              <Select
                value={selectedReceiver?.email}
                options={memberEmails.map((el) => ({
                  value: el.email,
                  label: `${el.memberType}: ${el.firstName} ${el.lastName} <${el.email}>`,
                }))}
                onChange={(value) => {
                  const data =
                    memberEmails.find((el) => el.email === value) ?? undefined;
                  setSelectedReceiver(data);
                }}
              />
            )}
          </div>
          <div className="subject-input">
            Subject:{' '}
            <FieldText
              value={subject}
              onChange={(e) => setSubject(e.target.value)}
            />
          </div>
          <div className="email-attachments">
            <button
              style={{ background: 'none', border: 'none' }}
              onClick={() => fileInputRef.current?.click()}
            >
              <AttachFileIcon />
            </button>
            <div className="attachments-list">
              {!(attachments.length + hostedFiles.length) ? (
                'No attachments'
              ) : (
                <>
                  {hostedFiles.map((el) => (
                    <div className="attachment-file">
                      <div>{el.fileName}</div>
                      <CancelIcon onClick={() => removeHostedFile(el._id)} />
                    </div>
                  ))}
                  {attachments.map((el, index) => (
                    <div className="attachment-file">
                      <div>{el.name}</div>
                      <CancelIcon onClick={() => removeAttachment(index)} />
                    </div>
                  ))}
                </>
              )}
            </div>
            <input
              type="file"
              ref={fileInputRef}
              style={{ display: 'none' }}
              onChange={(event) => {
                if (event.target.files) {
                  const files = Array.from(event.target.files);
                  setAttachments((prev) => [...prev, ...files]);
                }
              }}
              multiple
            />
          </div>
          <TextEditor value={body} onChange={(value) => setBody(value)} />
        </div>
        <div className="actions-buttons">
          <Button type="danger" onClick={clearData} className="cancel-button">
            <CancelIcon /> Cancel
          </Button>
          <Button
            type="default"
            onClick={() => {
              updatedId
                ? updateDraftEmail(
                    updatedId,
                    subject,
                    body,
                    attachments,
                    hostedFiles,
                  ).then(() => clearData())
                : draftEmail(subject, body, attachments).then(() =>
                    clearData(),
                  );
            }}
          >
            <EditIcon /> Draft
          </Button>
          <Button
            type="primary"
            onClick={() => {
              !updatedId
                ? sendEmail(
                    selectedReceiver?.email ?? '',
                    selectedReceiver?.memberType ?? MEMBER_TYPE.Candidate,
                    subject,
                    body,
                    attachments,
                  ).then(() => clearData())
                : sendDraftEmail(updatedId).then(() => clearData());
            }}
          >
            Send <RightArrowIcon />
          </Button>
        </div>
      </EmailContentContainer>
    );
  }

  if (!listEmails.length) {
    return (
      <EmailContentContainer>
        <div className="no-conversation">No emails yet</div>
        <div className="footer-email">
          <Button type="primary" onClick={() => setIsCreateEmailOpen(true)}>
            <PlusIcon /> New email
          </Button>
        </div>
      </EmailContentContainer>
    );
  }

  return (
    <EmailContentContainer>
      <div className="footer-email">
        <Button type="primary" onClick={() => setIsCreateEmailOpen(true)}>
          <PlusIcon /> New email
        </Button>
      </div>
      {listEmails.map((el) => {
        const sender = el.isReply ? user : el.member;
        const receiver = !el.isReply ? user : el.member;
        return (
          <div className="email-item-container">
            <div className="header-conversation">
              <div className="subject-header">{el.subject} </div>
              <div className="details-header">
                <div className="left-header">
                  <div className="sender-profile-image">
                    <a
                      href={
                        !el.isReply
                          ? '/'
                          : `/${
                              memberType === MEMBER_TYPE.Candidate
                                ? ERoute.CANDIDATES
                                : ERoute.CLIENTS
                            }/${sender?._id}`
                      }
                      target="_blank"
                      rel="noreferrer"
                    >
                      <UserIcon
                        firstName={sender?.firstName ?? ''}
                        lastName={sender?.lastName ?? ''}
                        entity={
                          !el.isReply
                            ? 'user'
                            : memberType === MEMBER_TYPE.Candidate
                            ? MEMBER_TYPE.Candidate
                            : MEMBER_TYPE.Client
                        }
                      />
                    </a>
                  </div>
                  <div className="sender-info-messenger">
                    <a
                      href={
                        !el.isReply
                          ? '/'
                          : `/${
                              memberType === MEMBER_TYPE.Candidate
                                ? ERoute.CANDIDATES
                                : ERoute.CLIENTS
                            }/${sender?._id}`
                      }
                      target="_blank"
                      rel="noreferrer"
                    >
                      <div className="sender-name">
                        {sender?.firstName + ' ' + sender?.lastName}
                      </div>
                      <div className="receiver-name">
                        to: {receiver?.firstName + ' ' + receiver?.lastName}
                      </div>
                    </a>
                  </div>
                </div>
                <div className="right-header">
                  <div className="time-receiving">
                    {formatDateTime(el.timestamp)}
                  </div>
                  {!el.isDraft && (
                    <>
                      <div
                        className="arrows"
                        title="reply"
                        onClick={() => onClickReply(el)}
                      >
                        <ArrowLeftUTurnIcon />
                      </div>
                      <div
                        className="arrows"
                        title="forward"
                        onClick={() => onClickTransfer(el)}
                      >
                        <ArrowRightUTurnIcon />
                      </div>{' '}
                    </>
                  )}
                  <div
                    className="icons"
                    title="delete"
                    onClick={() => onClickDelete(el)}
                  >
                    <DeleteIcon />
                  </div>
                  <div className="icons">
                    <DotsIcon />
                  </div>
                </div>
              </div>
            </div>

            <div className="content-conversation">
              <div className={`email-item`} key={el._id}>
                <div className="email-attachment-file-container">
                  {el?.hostedFiles?.map((file, index) => (
                    <div
                      className="attachment-file"
                      onClick={() => downloadFile(file)}
                      id={file._id}
                    >
                      <DownloadIcon /> {file.fileName}
                    </div>
                  ))}
                </div>
                <div
                  className="email-content"
                  dangerouslySetInnerHTML={{ __html: el.body }}
                />
              </div>
              {el.isDraft ? (
                <div className="actions-container">
                  <Button
                    className="email-actions"
                    onClick={() => onClickEdit(el)}
                  >
                    Edit <EditIcon />
                  </Button>
                </div>
              ) : (
                <div className="actions-container">
                  <Button
                    className="email-actions"
                    onClick={() => onClickReply(el)}
                  >
                    <ArrowLeftUTurnIcon /> Reply
                  </Button>
                  <Button
                    className="email-actions"
                    onClick={() => onClickTransfer(el)}
                  >
                    <ArrowRightUTurnIcon /> Forward
                  </Button>
                </div>
              )}
            </div>
          </div>
        );
      })}
    </EmailContentContainer>
  );
};
