import { StatisticsCardContainer } from './statistics-card.style';

type Props = {
  title: string;
  value: number;
  differencePercentage: number;
};
export const StatisticsCard = ({
  differencePercentage: percentage,
  title,
  value,
}: Props) => {
  return (
    <StatisticsCardContainer>
      <div className="title">{title}</div>
      <div className="stats-value">{value}</div>
      {/* <div className="percentage">
        <span>{Math.abs(percentage)} % </span>{' '}
        <span className="text">
          {percentage < 0 ? 'decreased' : 'increased'} vs last month
        </span>
      </div> */}
    </StatisticsCardContainer>
  );
};
