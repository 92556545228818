import React, { useRef, useState } from 'react';
import { DragDropIcon, UploadIcon } from '../../icons';
import { Button } from '../ui';
import { DragDropUploadContainer } from './drag-drop-upload.style';

type DragDropUploadProps = {
  onUpload: (files: File[]) => Promise<void>;
};

export const DragDropUpload = ({ onUpload }: DragDropUploadProps) => {
  const fileInputRef = useRef<any>(null);
  const [isDragging, setIsDragging] = useState(false);
  const [isUploading, setIsUploading] = useState(false);

  const handleButtonClick = () => {
    fileInputRef.current.click();
  };

  const handleDragOver = (e: React.DragEvent) => {
    e.preventDefault();
    setIsDragging(true);
  };

  const handleDragLeave = () => {
    setIsDragging(false);
  };

  const handleDrop = async (e: React.DragEvent) => {
    e.preventDefault();
    setIsDragging(false);
    const selectedFiles = Array.from(e.dataTransfer?.files ?? []);
    if (selectedFiles.length) {
      setIsUploading(true);
      await onUpload(selectedFiles);
      setIsUploading(false);
    }
  };

  const handleUpload = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFiles = Array.from(e.target?.files ?? []);
    if (selectedFiles.length) {
      setIsUploading(true);
      await onUpload(selectedFiles);
      e.target.value = '';
      setIsUploading(false);
    }
  };

  return (
    <DragDropUploadContainer
      onDragOver={handleDragOver}
      onDragLeave={handleDragLeave}
      onDrop={handleDrop}
      isDragging={isDragging}
    >
      <DragDropIcon />
      <div className="drag-drop-description">
        {!isUploading ? (
          <>
            <div className="drag-drop-title">Drag & Drop here to upload</div>
            <div className="drag-drop-subtitle">
              Or select file from your computer
            </div>
          </>
        ) : (
          <>
            <br />
            <div className="drag-drop-title">
              Uploading in progress, please wait...
            </div>
          </>
        )}
      </div>
      <input
        type="file"
        ref={fileInputRef}
        onChange={handleUpload}
        style={{ display: 'none' }}
        disabled={isUploading}
        multiple
      />
      <Button type="primary" onClick={handleButtonClick} disabled={isUploading}>
        <UploadIcon />
        Upload File
      </Button>
    </DragDropUploadContainer>
  );
};
