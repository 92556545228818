import { useNavigate, useParams } from 'react-router-dom';
import { useEffect, useMemo, useState } from 'react';
import {
  CardContainer,
  MessageList,
  Select,
  SearchInput,
  MessageContent,
} from '../../components';
import { MessagesPageContainer } from './messages.style';
import {
  Candidate,
  ChatListItemDto,
  Client,
  EmailsCandidatesCRM,
  EmailsClientsCRM,
  HostedFile,
  EWebsocketType,
  WhatsappCandidatesCRMChat,
  MEMBER_TYPE,
} from '../../backend/careo-api';
import {
  AxiosInstance,
  AxiosInstanceErrorResponse,
  draftEmailRequest,
  sendEmailRequest,
  sendWhatsappRequest,
  socket,
  updateDraftEmailRequest,
} from '../../utils';
import { PagesUrls } from '../../routes/page-urls';
import { isCRMApp } from '../../environment/app.type';
import { toast } from 'react-toastify';
import { EmailContent } from '../../components/messages/messages-content/emails-content.component';

type MessagesPageProps = {
  type: MEMBER_TYPE;
};

export enum MessageSender {
  Candidate = 'candidate',
  Client = 'client',
  User = 'user',
}

export interface WhatsappMessageUI
  extends Omit<WhatsappCandidatesCRMChat, 'candidate'> {
  receiverId: string;
  isLoading: boolean;
}

export interface EmailsMessageUi
  extends Omit<EmailsCandidatesCRM, 'candidate'> {
  receiverId: string;
  member: Candidate | Client;
}

export enum SocialPlatforms {
  Whatsapp = 'whatsapp',
  Email = 'email',
}

export enum BOX_TYPE {
  INBOX = 'inbox',
  DRAFTS = 'drafts',
  BIN = 'bin',
}

interface SocketMessageEvent {
  member: Candidate | Client;
  memberType: MEMBER_TYPE;
  userId: string;
  message?: string;
  file?: HostedFile;
  files?: HostedFile[];
}

interface SocketEmailEvent {
  member: Candidate | Client;
  memberType: MEMBER_TYPE;
  userId: string;
  subject: string;
  body: string;
  files: HostedFile[];
}

export const MessagesPage = ({
  type: selectedMemberType,
}: MessagesPageProps) => {
  const navigate = useNavigate();
  const { memberId: selectedMemberId } = useParams();

  const [platform, setPlatform] = useState(SocialPlatforms.Whatsapp);
  const [emailType, setEmailType] = useState<BOX_TYPE>();
  const [search, setSearch] = useState('');

  const [candidates, setCandidates] = useState<Candidate[]>([]);
  const [clients, setClients] = useState<Client[]>([]);

  const [recentMessages, setRecentMessages] = useState<{
    isLoading: boolean;
    data: ChatListItemDto[];
  }>({ isLoading: true, data: [] });

  const [messages, setMessages] = useState<{
    isLoading: boolean;
    data: WhatsappMessageUI[];
  }>({ isLoading: true, data: [] });

  const [emails, setEmails] = useState<{
    isLoading: boolean;
    data: EmailsMessageUi[];
  }>({ isLoading: true, data: [] });

  const selectedMember: Client | Candidate = useMemo(() => {
    const members: any[] =
      selectedMemberType === MEMBER_TYPE.Candidate ? candidates : clients;
    return members.find((c) => c._id === selectedMemberId);
  }, [selectedMemberId, candidates, clients]);

  const filteredRecentMessages = useMemo(
    () =>
      recentMessages.data.filter((el) =>
        (el.member.firstName + ' ' + el.member.lastName)
          .toLocaleLowerCase()
          .includes(search.toLocaleLowerCase()),
      ),
    [search, recentMessages],
  );

  const getLogs = async (memberId: string) => {
    setMessages({ isLoading: true, data: [] });
    setEmails({ isLoading: true, data: [] });
    await AxiosInstance.socialPlatforms
      .socialPlatformsControllerFetchMemberChats(
        platform,
        process.env.REACT_APP_NAME!,
        selectedMemberType,
        memberId,
        {
          box: emailType ?? '',
        },
      )
      .then(async (response) => {
        if (platform === SocialPlatforms.Whatsapp) {
          setMessages({
            isLoading: false,
            data: response as unknown as WhatsappMessageUI[],
          });
        } else {
          setEmails({
            isLoading: false,
            data: (
              (response as unknown as any[]).map(
                (el: EmailsCandidatesCRM | EmailsClientsCRM) => {
                  return {
                    ...el,
                    member:
                      selectedMemberType === MEMBER_TYPE.Candidate
                        ? (el as EmailsCandidatesCRM).candidate
                        : (el as EmailsClientsCRM).client,
                    sender: el.isReply
                      ? selectedMemberType === MEMBER_TYPE.Candidate
                        ? MessageSender.Candidate
                        : MessageSender.Client
                      : MessageSender.User,
                  };
                },
              ) as unknown as EmailsMessageUi[]
            ).sort(
              (a, b) =>
                new Date(b.timestamp).getTime() -
                new Date(a.timestamp).getTime(),
            ),
          });
        }

        AxiosInstance.socialPlatforms
          .socialPlatformsControllerMarkChatAsRead(
            platform,
            process.env.REACT_APP_NAME!,
            selectedMemberType,
            {
              memberId,
            },
          )
          .catch((error) => {
            console.log('error');
            console.log(error);
          });

        setRecentMessages((prev) => {
          const index = prev.data.findIndex(
            (el) => el.member._id === memberId && el.unreadMessagesCount,
          );

          if (index >= 0) {
            const updatedData = [...prev.data];
            updatedData[index] = {
              ...updatedData[index],
              unreadMessagesCount: 0,
            };
            return { ...prev, data: updatedData };
          }

          return prev;
        });
      })
      .catch(() => {
        setMessages({ isLoading: false, data: [] });
        setEmails({ isLoading: false, data: [] });
      });
  };

  //  #################### MESSAGES ####################
  const sendMessageAndAttachmentWhatsapp = (
    recipientId: string,
    message: string,
    attachment?: File,
  ) => {
    const messageObject: WhatsappMessageUI = {
      _id: new Date().toISOString(),
      receiverId: selectedMemberId ?? '',
      message,
      hostedFile: attachment ? ({} as any) : undefined,
      timestamp: new Date(),
      isLoading: attachment ? true : false,
      isRead: false,
      isReply: false,
    };

    let indexArray: number = 0;
    setMessages((prev) => {
      const result = [...prev.data, messageObject];
      indexArray = result.length - 1;
      return { isLoading: false, data: result };
    });
    updateRecentMessages(selectedMember, message);
    sendWhatsappRequest(
      recipientId,
      selectedMemberType,
      message,
      attachment,
    ).then((response: { message: string; file?: HostedFile }) => {
      setMessages((prev) => {
        const item = prev.data[indexArray];
        prev.data[indexArray] = {
          ...item,
          hostedFile: response.file ?? undefined,
          isLoading: false,
        };
        return { ...prev };
      });
    });
  };

  const updateRecentMessages = (
    member: Client | Candidate,
    message: string,
  ) => {
    setRecentMessages((prev) => {
      const index = prev.data.findIndex((el) => el.member._id === member._id);
      const list = [...prev.data];

      if (index >= 0) {
        list[index] = {
          ...list[index],
          lastMessage: message ?? '',
          lastMessageTimestamp: new Date().toJSON(),
          unreadMessagesCount:
            selectedMemberId !== member._id
              ? list[index].unreadMessagesCount + 1
              : 0,
        };
      } else {
        list.push({
          member,
          lastMessage: message ?? '',
          lastMessageTimestamp: new Date().toJSON(),
          unreadMessagesCount: selectedMemberId !== member._id ? 1 : 0,
          isReply: true,
        });
      }

      list.sort(
        (a, b) =>
          new Date(b.lastMessageTimestamp).getTime() -
          new Date(a.lastMessageTimestamp).getTime(),
      );

      return { ...prev, data: list };
    });
  };

  //  #################### EMAILS ####################
  const sendEmail = async (
    email: string,
    memberType2: string,
    subject: string,
    body: string,
    attachments: File[],
  ) => {
    if (!subject || !body) {
      toast.warning('Subject and body are required !');
      return;
    }

    await sendEmailRequest(email, memberType2, subject, body, attachments)
      .then((response) => {
        toast.success('Email sent successfully');

        if (emailType === BOX_TYPE.INBOX) {
          const member: Candidate | Client =
            memberType2 === MEMBER_TYPE.Candidate
              ? response.candidate
              : response.client;

          console.log('response');
          console.log({
            ...response,
            member,
          });

          if (selectedMember.email === email) {
            setEmails((prev) => ({
              isLoading: false,
              data: [
                {
                  ...response,
                  member,
                },
                ...prev.data,
              ],
            }));
          }

          if (memberType2 === selectedMemberType) {
            updateRecentMessages(member, response.subject);
          }
        }
      })
      .catch((error) => {
        toast.error(error?.response?.data ?? 'Something went wrong');
      });
  };

  const draftEmail = async (
    subject: string,
    body: string,
    attachments: File[],
  ) => {
    if (!subject || !body) {
      toast.warning('Subject and body are required !');
      return;
    }

    await draftEmailRequest(
      selectedMember?.email ?? '',
      selectedMemberType,
      subject,
      body,
      attachments,
    )
      .then((response) => {
        toast.success('Email saved successfully');
        if (emailType === BOX_TYPE.DRAFTS) {
          const member =
            selectedMemberType === MEMBER_TYPE.Candidate
              ? response.candidate
              : response.client;
          setEmails((prev) => ({
            isLoading: false,
            data: [
              {
                ...response,
                member,
              },
              ...prev.data,
            ],
          }));
          updateRecentMessages(member, response.subject);
        }
      })
      .catch((error) => {
        toast.error(error?.response?.data ?? 'Something went wrong');
      });
  };

  const updateDraftEmail = async (
    id: string,
    subject: string,
    body: string,
    attachments: File[],
    hostedFiles: HostedFile[],
  ) => {
    if (!subject || !body) {
      toast.warning('Subject and body are required !');
      return;
    }

    await updateDraftEmailRequest(
      id,
      selectedMember?.email ?? '',
      selectedMemberType,
      subject,
      body,
      attachments,
      hostedFiles,
    )
      .then((response) => {
        toast.success('Email saved successfully');
        const draftEmailIndex = emails.data.findIndex((el) => el._id === id);
        if (draftEmailIndex >= 0) {
          const newDraftEmail: EmailsMessageUi = {
            ...emails.data[draftEmailIndex],
            subject: response.subject,
            body: response.body,
            hostedFiles: response.hostedFiles,
          };
          setEmails((prev) => {
            let list = prev.data;
            list[0] = newDraftEmail;
            return {
              isLoading: false,
              data: [...list],
            };
          });
        }
      })
      .catch((error) => {
        toast.error(error?.response?.data ?? 'Something went wrong');
      });
  };

  const sendDraftEmail = async (id: string) => {
    AxiosInstance.emailsDrafts
      .emailDraftsControllerSendDraftEmail(process.env.REACT_APP_NAME!, id, {
        memberType: selectedMemberType,
      })
      .then(() => {
        toast.success('Email sent successfully');
        setEmails((prev) => {
          const filteredEmails = prev.data.filter((el) => el._id !== id);

          setRecentMessages((prevRecent) => {
            const list = prevRecent.data.filter(
              (el) => el.member._id !== selectedMember._id,
            );

            if (filteredEmails.length) {
              if (list.length) {
                const firstEmail = filteredEmails[0];

                list.push({
                  isReply: false,
                  lastMessage: firstEmail.subject,
                  member: selectedMember,
                  lastMessageTimestamp: firstEmail.timestamp,
                  unreadMessagesCount: 0,
                });

                list.sort(
                  (a, b) =>
                    new Date(b.lastMessageTimestamp).getTime() -
                    new Date(a.lastMessageTimestamp).getTime(),
                );
              }
            }

            return { isLoading: false, data: list };
          });

          return { isLoading: false, data: filteredEmails };
        });
      })
      .catch((error) => {
        toast.error(error?.response?.data ?? 'Something went wrong');
      });
  };

  const deleteEmail = async (id: string, isDraft: boolean) => {
    const deleteEmailRequest = isDraft
      ? AxiosInstance.emailsDrafts.emailDraftsControllerDeleteEmail
      : AxiosInstance.emails.emailsControllerDeleteEmail;

    await deleteEmailRequest(process.env.REACT_APP_NAME!, id, {
      memberType: selectedMemberType,
    })
      .then(() => {
        toast.success('Email deleted successfully');
        setEmails((prev) => {
          const filteredEmails = prev.data.filter((el) => el._id !== id);

          setRecentMessages((prevRecent) => {
            const list = prevRecent.data.filter(
              (el) => el.member._id !== selectedMember._id,
            );

            if (filteredEmails.length) {
              if (list.length) {
                const firstEmail = filteredEmails[0];

                list.push({
                  isReply: false,
                  lastMessage: firstEmail.subject,
                  member: selectedMember,
                  lastMessageTimestamp: firstEmail.timestamp,
                  unreadMessagesCount: 0,
                });

                list.sort(
                  (a, b) =>
                    new Date(b.lastMessageTimestamp).getTime() -
                    new Date(a.lastMessageTimestamp).getTime(),
                );
              }
            }

            return { isLoading: false, data: list };
          });

          return { isLoading: false, data: filteredEmails };
        });
      })
      .catch((error) => {
        toast.error(error?.response?.data ?? 'Something went wrong');
      });
  };

  const onClickSelectUserConversation = (memberId: string) => {
    navigate(`${PagesUrls.MESSAGES.Index}/${selectedMemberType}/${memberId}`);
  };

  const getCandidates = async () => {
    await AxiosInstance.candidates
      .candidatesControllerGetAllCandidates()
      .then((response: any) => {
        const result = response.data as unknown as Candidate[];
        setCandidates(result);
      })
      .catch((error: AxiosInstanceErrorResponse) => {
        toast.error(error.message);
      });
  };

  const getClients = async () => {
    AxiosInstance.clients
      .clientsControllerGetAllClients()
      .then((response) => {
        const result = response.data as unknown as Client[];
        setClients(result);
      })
      .catch((error: AxiosInstanceErrorResponse) => {
        toast.error(error.message);
      });
  };

  const manageData = async () => {
    await AxiosInstance.socialPlatforms
      .socialPlatformsControllerFetchChatList(
        platform,
        process.env.REACT_APP_NAME!,
        selectedMemberType,
        {
          box: emailType ?? '',
        },
      )
      .then((response) => {
        const result = response as unknown as ChatListItemDto[];
        setRecentMessages({
          isLoading: false,
          data: result.sort(
            (a, b) =>
              new Date(b.lastMessageTimestamp).getTime() -
              new Date(a.lastMessageTimestamp).getTime(),
          ),
        });
      })
      .catch((error: AxiosInstanceErrorResponse) => {
        console.log('error');
        console.log(error);
      });
  };

  useEffect(() => {
    const messageHandler = (messageData: SocketMessageEvent) => {
      const { member, message, memberType, file } = messageData;
      if (
        platform === SocialPlatforms.Whatsapp &&
        selectedMemberType === memberType
      ) {
        updateRecentMessages(member, message ?? '');
      }
      if (selectedMemberId !== member._id) return;

      const messageObject: WhatsappMessageUI = {
        _id: new Date().toISOString(),
        receiverId: selectedMemberId ?? '',
        message: message ?? '',
        hostedFile: file ? ({} as any) : undefined,
        timestamp: new Date(),
        isLoading: false,
        isRead: false,
        isReply: true,
      };
      setMessages((prev) => ({
        isLoading: false,
        data: [...prev.data, messageObject],
      }));

      AxiosInstance.socialPlatforms
        .socialPlatformsControllerMarkChatAsRead(
          platform,
          process.env.REACT_APP_NAME!,
          memberType,
          {
            memberId: selectedMemberId,
          },
        )
        .catch((error) => {
          console.log('error');
          console.log(error);
        });
    };

    const emailHandler = (emailData: SocketEmailEvent) => {
      const { member, memberType, subject, body, files } = emailData;
      if (
        platform === SocialPlatforms.Email &&
        emailType === BOX_TYPE.INBOX &&
        selectedMemberType === memberType
      ) {
        updateRecentMessages(member, subject ?? '');
      }
      if (selectedMemberId !== member._id) return;
      const newObject: EmailsMessageUi = {
        _id: new Date().toUTCString(),
        subject: subject,
        body: body,
        timestamp: new Date().toUTCString(),
        isReply: false,
        receiverId: selectedMemberId ?? '',
        hostedFiles: files ?? [],
        isRead: false,
        member: selectedMember,
        isDraft: false,
      };

      setEmails((prev) => ({
        isLoading: false,
        data: [newObject, ...prev.data],
      }));
    };

    socket.on(EWebsocketType.Message, messageHandler);
    socket.on(EWebsocketType.Email, emailHandler);

    return () => {
      socket.off(EWebsocketType.Message, messageHandler);
      socket.off(EWebsocketType.Email, emailHandler);
    };
  }, [socket, selectedMemberId]);

  useEffect(() => {
    manageData();
  }, [selectedMemberType, platform, emailType]);

  useEffect(() => {
    if (selectedMemberId) {
      socket?.emit('select', selectedMemberId);
      getLogs(selectedMemberId);
    }
  }, [selectedMemberId]);

  useEffect(() => {
    getCandidates();
    getClients();
  }, []);

  return (
    <MessagesPageContainer>
      <div className="messages-header">
        <div className="left-filter">
          <div
            className={`filter-item ${
              selectedMemberType === MEMBER_TYPE.Candidate && 'active'
            }`}
            onClick={() => {
              if (selectedMemberType !== MEMBER_TYPE.Candidate) {
                setRecentMessages({ data: [], isLoading: true });
                navigate(PagesUrls.MESSAGES.Candidate);
              }
            }}
          >
            Candidates
          </div>
          {isCRMApp && (
            <div
              className={`filter-item ${
                selectedMemberType === MEMBER_TYPE.Client && 'active'
              }`}
              onClick={() => {
                if (selectedMemberType !== MEMBER_TYPE.Client) {
                  setRecentMessages({ data: [], isLoading: true });
                  navigate(PagesUrls.MESSAGES.Client);
                }
              }}
            >
              Clients
            </div>
          )}
        </div>
        <div className="right-filter">
          <Select
            label=""
            required
            options={[
              { label: 'Whatsapp', value: SocialPlatforms.Whatsapp },
              { label: 'Email', value: SocialPlatforms.Email },
            ]}
            value={platform}
            onChange={(value) => {
              if (value) {
                setRecentMessages({ data: [], isLoading: true });
                navigate(`${PagesUrls.MESSAGES.Index}/${selectedMemberType}`);
                setPlatform(value as SocialPlatforms);
                if (value === SocialPlatforms.Email) {
                  setEmailType(BOX_TYPE.INBOX);
                } else {
                  setEmailType(undefined);
                }
              }
            }}
          />
          <Select
            label=""
            required
            options={[
              { label: 'Inbox', value: BOX_TYPE.INBOX },
              { label: 'Draft', value: BOX_TYPE.DRAFTS },
              { label: 'Bin', value: BOX_TYPE.BIN },
            ]}
            disabled={platform !== SocialPlatforms.Email}
            value={platform === SocialPlatforms.Email ? emailType : ''}
            onChange={(value) => {
              if (value) {
                setRecentMessages({ data: [], isLoading: true });
                navigate(`${PagesUrls.MESSAGES.Index}/${selectedMemberType}`);
                setEmailType(value as BOX_TYPE);
              }
            }}
          />
          <SearchInput
            placeholder={'Search'}
            onChange={(e) => setSearch(e.target.value ?? '')}
          />
        </div>
      </div>
      <CardContainer className="messages-container">
        <MessageList
          memberType={selectedMemberType}
          isLoading={recentMessages.isLoading}
          data={filteredRecentMessages}
          selectedMemberId={selectedMemberId}
          onMemberSelected={onClickSelectUserConversation}
        />
        {platform === SocialPlatforms.Whatsapp ? (
          <MessageContent
            memberType={selectedMemberType}
            selectedMember={selectedMember}
            listMessages={messages.data}
            isLoading={messages.isLoading}
            sendMessageAndAttachmentWhatsapp={sendMessageAndAttachmentWhatsapp}
          />
        ) : (
          <EmailContent
            memberType={selectedMemberType}
            selectedMember={selectedMember}
            listEmails={emails.data}
            isLoading={emails.isLoading}
            sendEmail={sendEmail}
            draftEmail={draftEmail}
            updateDraftEmail={updateDraftEmail}
            sendDraftEmail={sendDraftEmail}
            memberEmails={[
              ...candidates.map((el) => ({
                firstName: el.firstName,
                lastName: el.lastName,
                email: el.email,
                memberType: MEMBER_TYPE.Candidate,
              })),
              ...clients.map((el) => ({
                firstName: el.firstName,
                lastName: el.lastName,
                email: el.email,
                memberType: MEMBER_TYPE.Client,
              })),
            ]}
            deleteEmail={deleteEmail}
          />
        )}
      </CardContainer>
    </MessagesPageContainer>
  );
};
