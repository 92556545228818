import React, { useEffect, useMemo, useState } from 'react';
import { useAuth } from '../../../contexts/auth.context';
import {
  Badge,
  Button,
  CardContainer,
  NewCandidateForm,
  SearchInput,
  SubTitlePage,
  TabFilter,
  Table,
  TitlePage,
  UpdateCandidateForm,
  UserIcon,
} from '../../../components';
import {
  DeleteIcon,
  DownloadIcon,
  EditIcon,
  EyeIcon,
  LeftArrowIcon,
  PlusIcon,
  RightArrowIcon,
  SortIcon,
} from '../../../icons';
import {
  TUIType,
  getCountiesOfSelectedRegions,
  getGradesOfSelectedLevel,
  jobLevelWithGrades,
  regionsWithCounties,
} from '../../../constants';
import {
  AxiosInstance,
  AxiosInstanceErrorResponse,
  ERoute,
  filterCandidatesLists,
  formatDate,
  getItemsOfPage,
  getNumberOfPages,
  onSelectSort,
} from '../../../utils';
import { SideModal, EModalTypes } from '../../../components/modals';
import { useNavigate } from 'react-router-dom';
import {
  Candidate,
  CandidatesList,
  Payroll,
  User,
} from '../../../backend/careo-api';
import { toast } from 'react-toastify';
import { isCRMApp } from '../../../environment/app.type';
import { ConfirmModal } from '../../../components/modals/confirm-modal/confirm-modal.component';
import { HeaderPageContainer } from '../../../components/header/header.style';
import {
  NewSharedCandidatesList,
  UpdateSharedCandidatesList,
} from '../../../components/shared-candidates';
import { MultipleSelect } from '../../../components/ui/inputs/select/multiple-select.component';

const numberOfItems = 8;

export const SharedCandidatesListPage = () => {
  const navigate = useNavigate();

  const { user } = useAuth();

  const [candidates, setCandidates] = useState<Candidate[]>([]);
  const [usersList, setUsersList] = useState<User[]>([]);
  const [candidatesListsDetails, setCandidatesListsDetails] = useState<
    CandidatesList[]
  >([]);
  const [candidatesListsDetailsLists, setCandidatesListsDetailsLists] =
    useState<CandidatesList[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);

  const [recordToDelete, setRecordToDelete] = useState<{
    item: CandidatesList;
    remove: boolean;
  } | null>(null);

  const [isModalCreateListOpen, setIsModalCreateListOpen] = useState(false);
  const [isModalUpdateListOpen, setIsModalUpdateListOpen] =
    useState<CandidatesList | null>(null);

  const [filter, setFilter] = useState({
    search: '',
    region: '',
    county: '',
    grade: '',
    level: '',
  });
  const [sort, setSort] = useState<{ key: string; value: '+' | '-' | '' }>({
    key: '',
    value: '',
  });

  const navigatePagination = (newPage: number) => {
    if (newPage <= 0 || newPage > totalPages) {
      return;
    }
    setCurrentPage(newPage);
  };

  const onClickEdit = (candidatesList: CandidatesList) => {
    setIsModalUpdateListOpen(candidatesList);
  };

  const onClickCreate = () => {
    setIsModalCreateListOpen(true);
  };

  const onClickView = (id: number | string) => {
    navigate(`/${ERoute.SHARED_CANDIDATES}/${id}`);
  };

  const getCandidatesListsDetails = async () => {
    await AxiosInstance.candidatesLists
      .candidatesListsControllerFindAll()
      .then((response) => {
        setCandidatesListsDetails(response.data as unknown as CandidatesList[]);
      })
      .catch((error: AxiosInstanceErrorResponse) => {
        toast.error(error.message);
      });
  };

  const deleteCandidateList = async () => {
    await AxiosInstance.candidatesLists
      .candidatesListsControllerRemove(recordToDelete?.item?._id!)
      .then(() => {
        toast.success('Candidate List Removed successfully');
        getCandidatesListsDetails();
      })
      .catch((error: AxiosInstanceErrorResponse) => {
        toast.error(error.message);
      });
    setRecordToDelete(null);
  };

  const disjoinCandidateList = async () => {
    await AxiosInstance.candidatesLists
      .candidatesListsControllerDisjoinList(recordToDelete?.item?._id!)
      .then(() => {
        toast.success('You have disjoined Candidate List successfully');
        getCandidatesListsDetails();
      })
      .catch((error: AxiosInstanceErrorResponse) => {
        toast.error(error.message);
      });

    setRecordToDelete(null);
  };

  const getCandidates = async () => {
    await AxiosInstance.candidates
      .candidatesControllerGetAllCandidates()
      .then((response) => {
        setCandidates(response.data as unknown as Candidate[]);
      })
      .catch((error: AxiosInstanceErrorResponse) => {
        toast.error(error.message);
      });
  };

  const getUsers = async () => {
    AxiosInstance.users
      .usersControllerGetAllUsers()
      .then((response) => {
        setUsersList(response as unknown as User[]);
      })
      .catch((error: AxiosInstanceErrorResponse) => {
        toast.error(error.message);
      });
  };

  const paginatedData = useMemo(() => {
    return getItemsOfPage(
      candidatesListsDetailsLists,
      currentPage,
      numberOfItems,
    );
  }, [candidatesListsDetailsLists, currentPage]);

  useEffect(() => {
    const filteredData = filterCandidatesLists(candidatesListsDetails, filter);

    const sortedData = filteredData.sort((a, b) => {
      if (!sort.key || !sort.value) return 0;

      let keyA, keyB;

      keyA = (a as any)?.[sort.key];
      keyB = (b as any)?.[sort.key];

      if (typeof keyA !== 'string') keyA = '';
      if (typeof keyB !== 'string') keyB = '';

      const result = keyA.localeCompare(keyB);
      return sort.value === '+' ? result : -result;
    });

    setCandidatesListsDetailsLists(sortedData);
    setCurrentPage(1);
    setTotalPages(getNumberOfPages(sortedData.length, numberOfItems));
  }, [candidatesListsDetails, filter, sort]);

  useEffect(() => {
    getCandidatesListsDetails();
    getCandidates();
    getUsers();
  }, []);

  return (
    <>
      <TabFilter
        filters={[
          {
            title: 'Candidates',
            url: ERoute.CANDIDATES,
          },
          {
            title: 'Lists',
            url: ERoute.SHARED_CANDIDATES,
          },
        ]}
      />
      <CardContainer>
        <HeaderPageContainer>
          <div className="left-container">
            <TitlePage>Candidate Lists</TitlePage>
            <SubTitlePage>Manage your Lists</SubTitlePage>
          </div>
          <div className="right-container">
            <Button type="primary" onClick={() => onClickCreate()}>
              <PlusIcon /> Create New List
            </Button>
          </div>
        </HeaderPageContainer>
        <div className="filter-container">
          <SearchInput
            placeholder="Search candidate list"
            onChange={(e) =>
              setFilter((prev) => ({ ...prev, search: e.target.value ?? '' }))
            }
          />
          <MultipleSelect
            placeholder="All Regions"
            options={[]}
            onChange={(e) => {}}
            disabled
          />
          <MultipleSelect
            placeholder="All Counties"
            options={[]}
            onChange={(e) => {}}
            disabled
          />

          <MultipleSelect
            placeholder="All Levels"
            options={[]}
            onChange={(e) => {}}
            disabled
          />
          <MultipleSelect
            placeholder="All Grades"
            options={[]}
            onChange={(e) => {}}
            disabled
          />
        </div>
        <div className="data-table-container">
          <Table>
            <thead>
              <tr>
                <th className="checkbox-table">
                  <input type="checkbox" />
                </th>
                <th onClick={() => onSelectSort('firstName', setSort)}>
                  <div>
                    <label>Name </label>
                    <SortIcon
                      value={sort.key === 'firstName' ? sort.value : ''}
                    />
                  </div>
                </th>
                <th onClick={() => onSelectSort('candidates', setSort)}>
                  <div>
                    <label>List candidates</label>
                    <SortIcon
                      value={sort.key === 'candidates' ? sort.value : ''}
                    />
                  </div>
                </th>
                <th onClick={() => onSelectSort('user', setSort)}>
                  <div>
                    <label>Line Manager</label>
                    <SortIcon value={sort.key === 'user' ? sort.value : ''} />
                  </div>
                </th>
                <th onClick={() => onSelectSort('county', setSort)}>
                  <div>
                    <label>Created on</label>
                    <SortIcon value={sort.key === 'county' ? sort.value : ''} />
                  </div>
                </th>
                <th onClick={() => onSelectSort('region', setSort)}>
                  <div>
                    <label>Last Edited</label>
                    <SortIcon value={sort.key === 'region' ? sort.value : ''} />
                  </div>
                </th>
                <th>
                  <div></div>
                </th>
              </tr>
            </thead>
            <tbody>
              {paginatedData.map((itemList) => {
                const candidatesName = itemList.candidates
                  .map((el) => el.firstName + ' ' + el.lastName)
                  .join(', ');

                const isUpdateDisabled = user?._id !== itemList.user._id;

                return (
                  <tr key={itemList._id}>
                    <td className="checkbox-table">
                      <input type="checkbox" />
                    </td>
                    <td>{itemList.name}</td>
                    <td title={candidatesName}>
                      <div className="candidates-names">{candidatesName}</div>
                    </td>
                    <td>
                      {itemList?.user.firstName} {itemList?.user.lastName}
                    </td>
                    <td>{formatDate(itemList.createdAt)} </td>
                    <td>
                      {itemList.updatedAt
                        ? formatDate(itemList.updatedAt)
                        : '-'}{' '}
                    </td>
                    <td>
                      <div className="action-item">
                        <div
                          className="view-icon"
                          onClick={() => onClickView(itemList._id)}
                        >
                          <EyeIcon />
                        </div>
                        <div
                          className={`edit-icon ${
                            isUpdateDisabled && 'disabled'
                          }`}
                          onClick={() =>
                            !isUpdateDisabled && onClickEdit(itemList)
                          }
                        >
                          <EditIcon />
                        </div>
                        <div
                          className={`delete-icon`}
                          onClick={() =>
                            setRecordToDelete({
                              item: itemList,
                              remove: !isUpdateDisabled,
                            })
                          }
                        >
                          <DeleteIcon />
                        </div>
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </div>
        <div className="pagination-container">
          <div className="navigation-container">
            <div
              className={`left-arrow ${currentPage === 1 && 'disabled'}`}
              onClick={() => navigatePagination(currentPage - 1)}
            >
              <LeftArrowIcon />
            </div>
            <div className="pages-list">
              {Array.from({ length: totalPages }, (_, index) => index + 1).map(
                (el) => (
                  <div
                    className={`page-number ${el === currentPage && 'active'}`}
                    onClick={() => navigatePagination(el)}
                  >
                    {el}
                  </div>
                ),
              )}
            </div>
            <div
              className={`right-arrow ${
                currentPage === totalPages && 'disabled'
              }`}
              onClick={() => navigatePagination(currentPage + 1)}
            >
              <RightArrowIcon />
            </div>
          </div>
          <div className="information-container">
            Showing {currentPage} to {totalPages} of{' '}
            {candidatesListsDetails.length} entries
          </div>
        </div>
      </CardContainer>
      <SideModal
        isOpen={isModalCreateListOpen}
        setIsOpen={setIsModalCreateListOpen}
        title={'Create New List'}
      >
        <NewSharedCandidatesList
          onCancel={() => setIsModalCreateListOpen(false)}
          onSuccess={() => {
            getCandidatesListsDetails();
            setIsModalCreateListOpen(false);
          }}
          candidates={candidates}
          users={usersList}
          selectedCandidatesIds={[]}
        />
      </SideModal>
      <SideModal
        isOpen={!!isModalUpdateListOpen}
        setIsOpen={() => setIsModalUpdateListOpen(null)}
        title={'Update List'}
      >
        <UpdateSharedCandidatesList
          onCancel={() => setIsModalUpdateListOpen(null)}
          onSuccess={() => {
            getCandidatesListsDetails();
            setIsModalUpdateListOpen(null);
          }}
          candidates={candidates}
          users={usersList}
          selectedCandidateList={isModalUpdateListOpen!}
        />
      </SideModal>
      <ConfirmModal
        isOpen={!!recordToDelete}
        title={
          recordToDelete?.remove
            ? 'Delete Candidate List'
            : 'Disjoin Candidate List'
        }
        onNegativeBtnClick={() => setRecordToDelete(null)}
        onPositiveBtnClick={() =>
          recordToDelete?.remove
            ? deleteCandidateList()
            : disjoinCandidateList()
        }
      >
        {recordToDelete?.remove ? (
          <>
            Do you want to delete <b>{recordToDelete?.item?.name}</b> ?
          </>
        ) : (
          <>
            Do you want to disjoin <b>{recordToDelete?.item?.name}</b> ?
          </>
        )}

        <div
          style={{
            fontSize: '15px',
            color: '#E6BB20',
          }}
        ></div>
      </ConfirmModal>
    </>
  );
};
