import {
  EApplicationApprovalStatus,
  EApplicationStatus,
  EJobShift,
  EJobLevel,
  ENhsPayGrade,
  ESpecialty,
  EAiJobShift,
} from '../backend/careo-api';
import { TUIType } from './theme';

export const genders = ['Male', 'Female'];
export const titles = ['Mr.', 'Mrs.', 'Miss', 'Ms.', 'Dr.', 'Sir', 'Lady'];
export const nationalities = [
  'Afghan',
  'Albanian',
  'Algerian',
  'American',
  'Andorran',
  'Angolan',
  'Antiguans',
  'Argentinean',
  'Armenian',
  'Australian',
  'Austrian',
  'Azerbaijani',
  'Bahamian',
  'Bahraini',
  'Bangladeshi',
  'Barbadian',
  'Barbudans',
  'Botswana',
  'Belarusian',
  'Belgian',
  'Belizean',
  'Beninese',
  'Bhutanese',
  'Bolivian',
  'Bosnian',
  'Brazilian',
  'British',
  'Bruneian',
  'Bulgarian',
  'Burkinabe',
  'Burmese',
  'Burundian',
  'Cambodian',
  'Cameroonian',
  'Canadian',
  'Cape Verdean',
  'Central African',
  'Chadian',
  'Chilean',
  'Chinese',
  'Colombian',
  'Comoran',
  'Congolese',
  'Costa Rican',
  'Croatian',
  'Cuban',
  'Cypriot',
  'Czech',
  'Danish',
  'Djibouti',
  'Dominican',
  'Dutch',
  'East Timorese',
  'Ecuadorean',
  'Egyptian',
  'Emirian',
  'Equatorial Guinean',
  'Eritrean',
  'Estonian',
  'Ethiopian',
  'Fijian',
  'Filipino',
  'Finnish',
  'French',
  'Gabonese',
  'Gambian',
  'Georgian',
  'German',
  'Ghanaian',
  'Greek',
  'Grenadian',
  'Guatemalan',
  'Guinea-Bissauan',
  'Guinean',
  'Guyanese',
  'Haitian',
  'Herzegovinian',
  'Honduran',
  'Hungarian',
  'I-Kiribati',
  'Icelander',
  'Indian',
  'Indonesian',
  'Iranian',
  'Iraqi',
  'Irish',
  'Israeli',
  'Italian',
  'Ivorian',
  'Jamaican',
  'Japanese',
  'Jordanian',
  'Kazakhstani',
  'Kenyan',
  'Kittian and Nevisian',
  'Kuwaiti',
  'Kyrgyz',
  'Laotian',
  'Latvian',
  'Lebanese',
  'Liberian',
  'Libyan',
  'Liechtensteiner',
  'Lithuanian',
  'Luxembourger',
  'Macedonian',
  'Malagasy',
  'Malawian',
  'Malaysian',
  'Maldivan',
  'Malian',
  'Maltese',
  'Marshallese',
  'Mauritanian',
  'Mauritian',
  'Mexican',
  'Micronesian',
  'Moldovan',
  'Monacan',
  'Mongolian',
  'Moroccan',
  'Mosotho',
  'Motswana',
  'Mozambican',
  'Namibian',
  'Nauruan',
  'Nepalese',
  'New Zealander',
  'Nicaraguan',
  'Nigerian',
  'Nigerien',
  'North Korean',
  'Northern Irish',
  'Norwegian',
  'Omani',
  'Pakistani',
  'Palauan',
  'Panamanian',
  'Papua New Guinean',
  'Paraguayan',
  'Peruvian',
  'Polish',
  'Portuguese',
  'Qatari',
  'Romanian',
  'Russian',
  'Rwandan',
  'Saint Lucian',
  'Salvadoran',
  'Samoan',
  'San Marinese',
  'Sao Tomean',
  'Saudi',
  'Scottish',
  'Senegalese',
  'Serbian',
  'Seychellois',
  'Sierra Leonean',
  'Singaporean',
  'Slovakian',
  'Slovenian',
  'Solomon Islander',
  'Somali',
  'South African',
  'South Korean',
  'Spanish',
  'Sri Lankan',
  'Sudanese',
  'Surinamer',
  'Swazi',
  'Swedish',
  'Swiss',
  'Syrian',
  'Taiwanese',
  'Tajik',
  'Tanzanian',
  'Thai',
  'Togolese',
  'Tongan',
  'Trinidadian or Tobagonian',
  'Tunisian',
  'Turkish',
  'Tuvaluan',
  'Ugandan',
  'Ukrainian',
  'Uruguayan',
  'Uzbekistani',
  'Venezuelan',
  'Vietnamese',
  'Welsh',
  'Yemenite',
  'Zambian',
  'Zimbabwean',
];

export const durations = [
  { label: '15 min', value: 900 },
  { label: '30 min', value: 1800 },
  { label: '45 min', value: 2700 },
  { label: '1 hour', value: 3600 },
  { label: '1 hour and 15 min', value: 4500 },
  { label: '1 hour and 30 min', value: 5400 },
  { label: '1 hour and 45 min', value: 6300 },
  { label: '2 hours', value: 7200 },
  { label: '2 hours and 15 min', value: 8100 },
  { label: '2 hours and 30 min', value: 9000 },
  { label: '2 hours and 45 min', value: 9900 },
  { label: '3 hours', value: 10800 },
];

export const daysTitleList = [
  { label: 'Mon', value: 1 },
  { label: 'Tue', value: 2 },
  { label: 'Wed', value: 3 },
  { label: 'Thu', value: 4 },
  { label: 'Fri', value: 5 },
  { label: 'Sat', value: 6 },
  { label: 'Sun', value: 7 },
];

export const monthsList = [
  { label: 'January', value: 0 },
  { label: 'February', value: 1 },
  { label: 'March', value: 2 },
  { label: 'April', value: 3 },
  { label: 'May', value: 4 },
  { label: 'June', value: 5 },
  { label: 'July', value: 6 },
  { label: 'August', value: 7 },
  { label: 'September', value: 8 },
  { label: 'October', value: 9 },
  { label: 'November', value: 10 },
  { label: 'December', value: 11 },
];

export const applicationStatusList = [
  {
    label: 'Referred',
    value: EApplicationStatus.Referred,
    isDraggableTo: true,
  },
  {
    label: 'AI Matched',
    value: EApplicationStatus.AiMatched,
    isDraggableTo: false,
  },
  {
    label: 'Rejected',
    value: EApplicationStatus.Rejected,
    isDraggableTo: true,
  },
  {
    label: 'Shortlist',
    value: EApplicationStatus.Shortlist,
    isDraggableTo: true,
  },
  {
    label: 'Placement',
    value: EApplicationStatus.Placement,
    isDraggableTo: true,
  },
];

export const applicationApprovalStatusList: {
  label: string;
  value: EApplicationApprovalStatus;
  type: TUIType;
}[] = [
  {
    label: 'Not Active',
    value: EApplicationApprovalStatus.NOT_ACTIVE,
    type: 'default',
  },
  {
    label: 'Pending',
    value: EApplicationApprovalStatus.PENDING,
    type: 'primary',
  },
  {
    label: 'Candidate Confirmed',
    value: EApplicationApprovalStatus.CANDIDATE_CONFIRMED,
    type: 'info',
  },
  {
    label: 'Candidate Rejected',
    value: EApplicationApprovalStatus.CANDIDATE_REJECTED,
    type: 'danger',
  },
  {
    label: 'Client Confirmed',
    value: EApplicationApprovalStatus.CLIENT_CONFIRMED,
    type: 'success',
  },
  {
    label: 'Client Rejected',
    value: EApplicationApprovalStatus.CLIENT_REJECTED,
    type: 'danger',
  },
  {
    label: 'Recruiter Approved',
    value: EApplicationApprovalStatus.RECRUITER_APPROVED,
    type: 'success',
  },
  {
    label: 'Recruiter Declined',
    value: EApplicationApprovalStatus.RECRUITER_DECLINED,
    type: 'danger',
  },
  {
    label: 'Recruiter Canceled',
    value: EApplicationApprovalStatus.RECRUITER_CANCELED,
    type: 'danger',
  },
  {
    label: 'Recruiter Stopped',
    value: EApplicationApprovalStatus.RECRUITER_STOPPED,
    type: 'danger',
  },
  {
    label: 'System Canceled',
    value: EApplicationApprovalStatus.SYSTEM_CANCELLED,
    type: 'danger',
  },
];

export const jobLevelWithGrades = [
  {
    level: EJobLevel.Nurse,
    grades: [
      ENhsPayGrade.Band5,
      ENhsPayGrade.Band6,
      ENhsPayGrade.Band7,
      ENhsPayGrade.Band8A,
      ENhsPayGrade.Band8B,
      ENhsPayGrade.Band8C,
      ENhsPayGrade.Band8D,
      ENhsPayGrade.Band9,
    ],
  },
  {
    level: EJobLevel.JuniorDoctor,
    grades: [
      ENhsPayGrade.FY1,
      ENhsPayGrade.FY2,
      ENhsPayGrade.ST1,
      ENhsPayGrade.ST2,
      ENhsPayGrade.SeniorHouseOfficer,
    ],
  },
  {
    level: EJobLevel.MiddleGrade,
    grades: [
      ENhsPayGrade.ST3,
      ENhsPayGrade.ST4,
      ENhsPayGrade.ST5,
      ENhsPayGrade.ST6,
      ENhsPayGrade.ST7,
      ENhsPayGrade.ST8,
      ENhsPayGrade.AssociateSpecialist,
      ENhsPayGrade.StaffGrade,
      ENhsPayGrade.SpecialtyDoctor,
    ],
  },
  {
    level: EJobLevel.Consultant,
    grades: [ENhsPayGrade.Consultant],
  },
];

export const getGradesOfSelectedLevel = (levels: string[]): string[] => {
  return jobLevelWithGrades
    .filter((el) => levels.includes(el.level))
    .reduce((acc, el) => acc.concat(el.grades), [] as string[]);
};

export const countriesWithRegions = [
  {
    country: 'England',
    regions: [
      'East of England',
      'South East',
      'South West',
      'North East',
      'North West',
      'East Midlands',
      'West Midlands',
      'Yorkshire and the Humber',
      'London',
    ],
  },
  {
    country: 'Scotland',
    regions: ['Scotland'],
  },
  {
    country: 'Wales',
    regions: ['Wales'],
  },
  {
    country: 'Northern Ireland',
    regions: ['Northern Ireland'],
  },
];

export const regionsWithCounties = [
  {
    region: 'East of England',
    counties: [
      'Bedfordshire',
      'Cambridgeshire',
      'Essex',
      'Hertfordshire',
      'Norfolk',
      'Suffolk',
    ],
  },
  {
    region: 'South East',
    counties: [
      'Berkshire',
      'Buckinghamshire',
      'East Sussex',
      'Hampshire',
      'Isle of Wight',
      'Kent',
      'Oxfordshire',
      'Surrey',
      'West Sussex',
    ],
  },
  {
    region: 'South West',
    counties: [
      'Bristol',
      'Cornwall',
      'Devon',
      'Dorset',
      'Gloucestershire',
      'North Somerset',
      'Somerset',
      'South Gloucestershire',
      'Wiltshire',
    ],
  },
  {
    region: 'North East',
    counties: ['County Durham', 'Northumberland', 'Tyne & Wear'],
  },
  {
    region: 'North West',
    counties: [
      'Cheshire',
      'Cumbria',
      'Greater Manchester',
      'Lancashire',
      'Merseyside',
    ],
  },
  {
    region: 'East Midlands',
    counties: [
      'Derbyshire',
      'Leicestershire',
      'Lincolnshire',
      'Northamptonshire',
      'Nottinghamshire',
      'Rutland',
    ],
  },
  {
    region: 'West Midlands',
    counties: [
      'Herefordshire',
      'Shropshire',
      'Staffordshire',
      'Warwickshire',
      'West Midlands',
      'Worcestershire',
    ],
  },
  {
    region: 'Yorkshire and the Humber',
    counties: [
      'East Riding of Yorkshire',
      'North Yorkshire',
      'South Yorkshire',
      'West Yorkshire',
    ],
  },
  {
    region: 'London',
    counties: ['Greater London'],
  },
  {
    region: 'Scotland',
    counties: [
      'Aberdeenshire',
      'Angus',
      'Argyll & Bute',
      'Ayrshire',
      'Banffshire',
      'Berwickshire',
      'Borders',
      'Caithness',
      'Clackmannanshire',
      'Dumfries & Galloway',
      'Dunbartonshire',
      'East Ayrshire',
      'East Dunbartonshire',
      'East Lothian',
      'East Renfrewshire',
      'Fife',
      'Highland',
      'Inverclyde',
      'Kincardineshire',
      'Lanarkshire',
      'Midlothian',
      'Moray',
      'North Ayrshire',
      'North Lanarkshire',
      'Orkney',
      'Perth & Kinross',
      'Renfrewshire',
      'Shetland',
      'South Ayrshire',
      'South Lanarkshire',
      'Stirlingshire',
      'West Dunbartonshire',
      'West Lothian',
      'Western Isles',
    ],
  },
  {
    region: 'Wales',
    counties: [
      'Blaenau Gwent',
      'Bridgend',
      'Caerphilly',
      'Cardiff',
      'Carmarthenshire',
      'Ceredigion',
      'Conwy',
      'Denbighshire',
      'Flintshire',
      'Gwynedd',
      'Isle of Anglesey',
      'Merthyr Tydfil',
      'Monmouthshire',
      'Neath Port Talbot',
      'Newport',
      'Pembrokeshire',
      'Powys',
      'Rhondda Cynon Taff',
      'Swansea',
      'Torfaen',
      'Vale of Glamorgan',
      'Wrexham',
    ],
  },
  {
    region: 'Northern Ireland',
    counties: [
      'Antrim',
      'Armagh',
      'Down',
      'Fermanagh',
      'Londonderry',
      'Tyrone',
    ],
  },
];

export const getCountiesOfSelectedRegions = (regions: string[]): string[] => {
  return regionsWithCounties
    .filter((regionWithCounties) => regions.includes(regionWithCounties.region))
    .reduce(
      (acc, regionWithCounties) => acc.concat(regionWithCounties.counties),
      [] as string[],
    );
};

export const daysOfWeekOptions = [
  { label: 'Sunday', value: 0 },
  { label: 'Monday', value: 1 },
  { label: 'Tuesday', value: 2 },
  { label: 'Wednesday', value: 3 },
  { label: 'Thursday', value: 4 },
  { label: 'Friday', value: 5 },
  { label: 'Saturday', value: 6 },
];

export const shiftList = [
  {
    label: 'Day',
    value: EJobShift.Day,
  },
  {
    label: 'Night',
    value: EJobShift.Night,
  },
];

export const availabilityTimeListOption = [
  {
    label: 'Day',
    value: EAiJobShift.Day,
  },
  {
    label: 'Night',
    value: EAiJobShift.Night,
  },
  {
    label: 'Full Day',
    value: EAiJobShift.FullDay,
  },
];

export const specialtiesList = Object.values(ESpecialty);
