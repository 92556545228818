import React, { useEffect, useState } from 'react';
import { useAuth } from '../../../contexts/auth.context';
import { LoginContainer } from './login.style';
import { AppIcon, AppleIcon, CheckIcon, GoogleIcon } from '../../../icons';
import { Link } from 'react-router-dom';
import { SignInDto } from '../../../backend/careo-api';
import { useForm } from 'react-hook-form';
import {
  AxiosInstance,
  AxiosInstanceErrorResponse,
  setToken,
} from '../../../utils';
import { Button, FieldText, TitlePage } from '../../../components';
import { yupResolver } from '@hookform/resolvers/yup';
import { AuthSchema } from '../../../utils/validators/auth.validator';

export const LoginPage = () => {
  const { login } = useAuth();
  const {
    register,
    getValues,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm<SignInDto>({
    resolver: yupResolver(AuthSchema),
  });

  const [errorMessage, setErrorMessage] = useState<string>();

  const onClickLogin = async () => {
    const values = getValues();
    await AxiosInstance.auth
      .authControllerSignIn(values)
      .then((response: any) => {
        login(response);
      })
      .catch((e: AxiosInstanceErrorResponse) => {
        if (e.status === 401) {
          setErrorMessage(
            'Wrong Email or Password please check your credentials',
          );
          setError('email', { message: '' });
          setError('password', { message: '' });
        }
        if (e.status === 400) {
          setErrorMessage(
            'Wrong Email or Password please check your credentials',
          );
          setError('email', { message: '' });
          setError('password', { message: '' });
        }
      });
  };

  return (
    <LoginContainer>
      <div className="left-container">
        <div className="image-container"></div>
        <div className="bottom-container">
          <AppIcon />
          <div className="title">
            Let’s empower your <br /> candidates today.
          </div>
          <div className="subtitle">
            We help to complete all your conveyancing needs easily
          </div>
        </div>
      </div>
      <div className="right-container">
        <div className="top-container"></div>
        <div className="content-container">
          <TitlePage className="text-center">
            Login first to your account
          </TitlePage>
          <div className="email-container">
            <div className="input-container">
              <FieldText
                type="email"
                label="Email Address"
                register={register('email', { required: true })}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') handleSubmit(onClickLogin)();
                }}
                error={errors.email}
              />
            </div>
          </div>
          <div className="email-container">
            <div className="input-container">
              <FieldText
                placeholder="Enter your password"
                type="password"
                label="Password"
                register={register('password', { required: true })}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') handleSubmit(onClickLogin)();
                }}
                error={errors.password}
              />
            </div>
          </div>
          <div className="forgot-remember-container">
            <div className="remember-container">
              <input type="checkbox" />
              Remember me
            </div>
            <Link to="" className="forgot-password">
              Forgot Password
            </Link>
          </div>
          {errorMessage && (
            <span
              style={{
                color: 'red',
                fontSize: '12px',
              }}
            >
              {errorMessage}
            </span>
          )}
          <Button type="primary" onClick={handleSubmit(onClickLogin)}>
            Login
          </Button>
          <div className="login-with-container">
            <hr />
            <label>Or login with</label>
            <hr />
          </div>
          <div className="options-connection-container">
            <div className="button">
              <GoogleIcon />
              Google
            </div>
            <div className="button">
              <AppleIcon />
              Apple
            </div>
          </div>
          <div className="register-option">
            You’re new in here? <Link to={''}>Create Account</Link>
          </div>
        </div>
        <div className="bottom-container">
          <div className="copyright">© 2024 Careo . All rights reserved.</div>
          <Link to={''}>Terms & Conditions</Link>
          <Link to={''}>Privacy Policy</Link>
        </div>
      </div>
    </LoginContainer>
  );
};
