import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { TitleCard, FieldText, Button, Textarea } from '../../ui';
import { useForm } from 'react-hook-form';
import {
  Candidate,
  CreateCampaignTemplateDto,
} from '../../../backend/careo-api';
import {
  AxiosInstance,
  AxiosInstanceErrorResponse,
  generateMessageFromTemplate,
} from '../../../utils';
import { toast } from 'react-toastify';
import { NewCampaignFormContainer } from '../new-campaign-form/new-campaign-form.style';
import { yupResolver } from '@hookform/resolvers/yup';
import { campaignTemplateSchema } from '../../../utils/validators/campaign.validator';

type NewCampaignTemplateFormProps = {
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  getCampaignTemplates: () => void;
};

export const NewCampaignTemplateForm = ({
  setIsOpen,
  getCampaignTemplates,
}: NewCampaignTemplateFormProps) => {
  const {
    register,
    getValues,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm<CreateCampaignTemplateDto>({
    resolver: yupResolver(campaignTemplateSchema as any),
  });
  const formValues = watch();

  const [result, setResult] = useState('');

  const onClickClose = () => {
    setIsOpen(false);
  };

  const onClickSubmit = () => {
    const values = getValues();

    AxiosInstance.campaignTemplates
      .campaignTemplatesControllerCreate({
        ...values,
      })
      .then(() => {
        toast.success('Campaign Template added successfully');
        getCampaignTemplates();
        onClickClose();
      })
      .catch((error: AxiosInstanceErrorResponse) => {
        toast.error(error.message);
      });
  };

  useEffect(() => {
    setResult(() => {
      return generateMessageFromTemplate(formValues.message, {
        title: 'Mr.',
        firstName: 'John',
        lastName: 'Doe',
      } as Candidate);
    });
  }, [formValues]);

  return (
    <NewCampaignFormContainer>
      <FieldText
        placeholder="Enter here ..."
        label="Template name"
        required
        register={register('name', { required: true })}
        error={errors.name}
      />
      <TitleCard>Message Options</TitleCard>
      <ul>
        <li>
          <b>[[title]]</b> : title of candidate (Mrs., Mr., Ms., Dr., Prof.)
        </li>
        <li>
          <b>[[firstName]]</b> : first name of candidate
        </li>
        <li>
          <b>[[lastName]]</b> : last name of candidate
        </li>
      </ul>
      <Textarea
        label={'Template Message'}
        required
        register={register('message', { required: true })}
        placeholder={`Dear [title] [lastName],
I hope you are doing well!`}
        rows={3}
        error={errors.message}
      />
      <Textarea label={'Result (Example)'} rows={3} value={result} disabled />
      <div className="form-actions">
        <Button onClick={onClickClose}>Cancel</Button>
        <Button type="primary" onClick={handleSubmit(onClickSubmit)}>
          Create
        </Button>
      </div>
    </NewCampaignFormContainer>
  );
};
