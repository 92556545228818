import { ClientsListPage, ClientDetailsPage } from '../pages/client';
import { IRoute } from '../types/routes.type';
import { PagesUrls } from './page-urls';

export const ClientsRoutes: IRoute[] = [
  {
    titleKey: PagesUrls.CLIENTS.Index,
    path: PagesUrls.CLIENTS.Index,
    element: <ClientsListPage />,
    isVisibleOnSidebar: true,
  },
  {
    path: PagesUrls.CLIENTS.Details,
    element: <ClientDetailsPage />,
  },
];
