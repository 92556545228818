export * from './auth.validator';
export * from './availability.validator';
export * from './campaign.validator';
export * from './candidates-list.validator';
export * from './candidates.validator';
export * from './clients.validator';
export * from './compliance.validator';
export * from './department.validator';
export * from './document.validator';
export * from './payrolls.validator';
export * from './placement.validator';
export * from './user.validator';
export * from './vacancy.validator';
