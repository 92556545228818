import React, { useEffect, useState } from 'react';
import {
  CallIcon,
  DeleteIcon,
  DownloadIcon,
  DragDropIcon,
  LeftArrowIcon,
  MessageIcon,
  UploadIcon,
} from '../../../icons';
import {
  ATag,
  Button,
  CardContainer,
  Table,
  TitleCard,
  TitlePage,
  UserIcon,
} from '../../../components';
import { ClientDetailsContainer } from './client-details.style';
import { useNavigate, useParams } from 'react-router-dom';
import { AxiosInstance, AxiosInstanceErrorResponse } from '../../../utils';
import { Client, HostedFile, User } from '../../../backend/careo-api';
import { toast } from 'react-toastify';
import { NewDepartmentForm } from '../../../components/client/new-department-form/new-department-form.component';
import { SideModal } from '../../../components/modals';
import { AddressDetails } from '../../../components/address/address-details.component';
import { DocumentsListComponent } from '../../../components/document';
import { DragDropUpload } from '../../../components/drag-drop-upload/drag-drop-upload.component';

enum EClientDetailsTabs {
  GENERAL = 'General',
  CONTACT = 'Contact',
  PAYROLL = 'Payroll',
  DOCUMENTS = 'Documents',
  DEPARTMENTS = 'Departments',
}

const tabs = [
  EClientDetailsTabs.GENERAL,
  EClientDetailsTabs.CONTACT,
  EClientDetailsTabs.DEPARTMENTS,
  EClientDetailsTabs.PAYROLL,
  EClientDetailsTabs.DOCUMENTS,
];

export const ClientDetailsPage = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const [selectedTab, setSelectedTab] = useState(EClientDetailsTabs.GENERAL);
  const [client, setClient] = useState<Client | null>(null);
  const [clientDocuments, setClientDocuments] = useState<HostedFile[]>([]);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const onClickDelete = (id: string | number) => {};
  const onClickDownload = (id: string | number) => {};
  const onClickGoBack = () => {
    navigate(-1);
  };

  const getClientDetails = () => {
    AxiosInstance.clients
      .clientsControllerGetClient(id!)
      .then((response) => {
        setClient(response as any);
      })
      .catch(() => {
        toast.error('Something went wrong');
      });
  };

  const onClickDeleteDepartment = (idDepartment: string) => {
    AxiosInstance.clients
      .clientsControllerDeleteDepartment(id!, idDepartment)
      .then((response) => {
        getClientDetails();
        toast.success('Department removed successfully');
      })
      .catch(() => {
        toast.error('Something went wrong');
      });
  };

  const getClientDocuments = () => {
    AxiosInstance.files
      .filesControllerFindClientDocs(id!)
      .then((response) => {
        setClientDocuments(response as any);
      })
      .catch(() => {
        toast.error('Failed to get client documents');
      });
  };

  useEffect(() => {
    getClientDetails();
    getClientDocuments();
  }, []);

  if (!client) {
    return <></>;
  }

  return (
    <>
      <ClientDetailsContainer>
        <div className="client-header">
          <div className="back-button" onClick={onClickGoBack}>
            <LeftArrowIcon />
          </div>
          <TitlePage>Detail Client</TitlePage>
        </div>
        <CardContainer className="page-summary-container">
          <div className="module-name">
            <UserIcon
              firstName={client.firstName}
              lastName={client.lastName}
              entity="client"
            />
            <div className="module-info">
              <div className="name">
                {client.firstName} {client.lastName}
              </div>
              <div className="availability">{client.address?.city}</div>
            </div>
          </div>
          <div className="module-contact">
            <CallIcon />
            <div>{client.phoneNumber}</div>
          </div>
          <div className="module-contact">
            <MessageIcon />
            <div>{client.email}</div>
          </div>
        </CardContainer>
        <CardContainer className="client-information-container">
          <div className="tab-container">
            <div className="tab-items-list">
              {tabs.map((el) => (
                <ATag
                  className={`tab-item ${el === selectedTab && 'active'}`}
                  onClick={() => setSelectedTab(el)}
                >
                  {el}
                </ATag>
              ))}
            </div>
            <div className="tab-content-container">
              {selectedTab === EClientDetailsTabs.GENERAL && (
                <>
                  <div className="info-card">
                    <div className="info-card-title">Client Info</div>
                    <hr />
                    <div className="info-card-content row">
                      <div className="col-md-6 row">
                        <div className="info-item-title col-4">Client Name</div>
                        <div className="info-item-data col-8">
                          {client.clientName}
                        </div>
                      </div>
                      <div className="col-md-6 row">
                        <div className="info-item-title col-4">
                          Client Site Name
                        </div>
                        <div className="info-item-data col-8">
                          {client.clientSiteName}
                        </div>
                      </div>
                      <div className="col-md-6 row">
                        <div className="info-item-title col-4">Client Site</div>
                        <div className="info-item-data col-8">
                          {client.clientSite}
                        </div>
                      </div>
                      <div className="col-md-6 row">
                        <div className="info-item-title col-4">
                          Line manager
                        </div>
                        <div className="info-item-data col-8">
                          {client.user.firstName} {client.user.lastName}
                        </div>
                      </div>
                      <div className="col-md-6 row">
                        <div className="info-item-title col-4">
                          Email Address
                        </div>
                        <div className="info-item-data col-8">
                          {client.email}
                        </div>
                      </div>
                      <div className="col-md-6 row">
                        <div className="info-item-title col-4">
                          Phone Number
                        </div>
                        <div className="info-item-data col-8">
                          {client.phoneNumber}
                        </div>
                      </div>
                    </div>
                  </div>
                  <AddressDetails address={client.address} />
                </>
              )}
              {selectedTab === EClientDetailsTabs.CONTACT && (
                <div className="info-card">
                  <div className="info-card-title">Contact Details</div>
                  <hr />
                  <div className="info-card-content row">
                    <div className="col-md-6 row">
                      <div className="info-item-title col-4">Title</div>
                      <div className="info-item-data col-8">
                        {client?.title}
                      </div>
                    </div>
                    <div className="col-md-6 row">
                      <div className="info-item-title col-4">First Name</div>
                      <div className="info-item-data col-8">
                        {client?.firstName}
                      </div>
                    </div>
                    <div className="col-md-6 row">
                      <div className="info-item-title col-4">Last Name</div>
                      <div className="info-item-data col-8">
                        {client?.lastName}
                      </div>
                    </div>
                    <div className="col-md-6 row">
                      <div className="info-item-title col-4">Job Title</div>
                      <div className="info-item-data col-8">
                        {client?.jobTitle}
                      </div>
                    </div>
                    <div className="col-md-6 row">
                      <div className="info-item-title col-4">Email Address</div>
                      <div className="info-item-data col-8">
                        {client?.email}
                      </div>
                    </div>
                    <div className="col-md-6 row">
                      <div className="info-item-title col-4">Mobile Number</div>
                      <div className="info-item-data col-8">
                        {client?.phoneNumber}
                      </div>
                    </div>
                    <div className="col-md-6 row">
                      <div className="info-item-title col-4">
                        Work Phone Number
                      </div>
                      <div className="info-item-data col-8">
                        {client?.workPhoneNumber}
                      </div>
                    </div>
                    <div className="col-md-6 row">
                      <div className="info-item-title col-4">Website</div>
                      <div className="info-item-data col-8">
                        {client?.website}
                      </div>
                    </div>
                    <div className="col-md-6 row">
                      <div className="info-item-title col-4">Fax Number</div>
                      <div className="info-item-data col-8">
                        {client?.faxNumber}
                      </div>
                    </div>
                    <div className="col-md-6 row">
                      <div className="info-item-title col-4">Department</div>
                      <div className="info-item-data col-8">
                        {client?.address?.county}
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {selectedTab === EClientDetailsTabs.PAYROLL && (
                <div className="info-card">
                  <div className="info-card-title">Bank Account</div>
                  <hr />
                  <div className="info-card-content row">
                    <div className="col-md-6 row">
                      <div className="info-item-title col-4">Bank Name</div>
                      <div className="info-item-data col-8">
                        {client?.bank?.name}
                      </div>
                    </div>
                    <div className="col-md-6 row">
                      <div className="info-item-title col-4">Account Name</div>
                      <div className="info-item-data col-8">
                        {client?.bank?.accountName}
                      </div>
                    </div>
                    <div className="col-md-6 row">
                      <div className="info-item-title col-4">Branch</div>
                      <div className="info-item-data col-8">
                        {client?.address?.city}
                      </div>
                    </div>
                    <div className="col-md-6 row">
                      <div className="info-item-title col-4">
                        Account Number
                      </div>
                      <div className="info-item-data col-8">
                        {client?.bank?.accountNumber}
                      </div>
                    </div>
                    <div className="col-md-6 row">
                      <div className="info-item-title col-4">Type</div>
                      <div className="info-item-data col-8">- </div>
                    </div>
                    <div className="col-md-6 row">
                      <div className="info-item-title col-4">Sort Code</div>
                      <div className="info-item-data col-8">
                        {client?.bank?.sortNumber}
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {selectedTab === EClientDetailsTabs.DOCUMENTS && (
                <>
                  <div className="info-card">
                    <div className="info-card-title">Client Documents</div>
                    <hr />
                    <DocumentsListComponent
                      documents={clientDocuments}
                      onClickDelete={() => {}}
                      getDocuments={getClientDocuments}
                    />
                  </div>
                  <div className="info-card">
                    <DragDropUpload onUpload={(files: File[]) => ({} as any)} />
                  </div>
                </>
              )}
              {selectedTab === EClientDetailsTabs.DEPARTMENTS && (
                <>
                  <div className="info-card">
                    <div className="info-card-header">
                      <div className="info-card-title">Departments List</div>
                      <Button
                        type="primary"
                        onClick={() => setIsModalOpen(true)}
                      >
                        Add new department
                      </Button>
                    </div>
                    <hr />
                    <div
                      className="accordion"
                      id="accordionPanelsStayOpenExample"
                    >
                      {(client.departments ?? [])?.map((department, index) => {
                        return (
                          <div
                            className="accordion-item"
                            key={(department as any).any}
                          >
                            <h2
                              className="accordion-header"
                              id="panelsStayOpen-headingOne"
                            >
                              <button
                                className="accordion-button"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target={`#panelsStayOpen-${index}`}
                                aria-expanded="true"
                                aria-controls={`panelsStayOpen-${index}`}
                              >
                                {department.departmentContact} #{index + 1}
                              </button>
                            </h2>
                            <div
                              id={`panelsStayOpen-${index}`}
                              className="accordion-collapse collapse show"
                              aria-labelledby="panelsStayOpen-headingOne"
                            >
                              <div className="accordion-body">
                                <div className="info-card-content row mb-5">
                                  <div className="col-md-12">
                                    <TitleCard>Contact</TitleCard>
                                  </div>
                                  <div className="info-card-content row">
                                    <div className="col-md-6 row">
                                      <div className="info-item-title col-4">
                                        Title
                                      </div>
                                      <div className="info-item-data col-8">
                                        {department?.titleContact}
                                      </div>
                                    </div>
                                    <div className="col-md-6 row">
                                      <div className="info-item-title col-4">
                                        First Name
                                      </div>
                                      <div className="info-item-data col-8">
                                        {department?.firstNameContact}
                                      </div>
                                    </div>
                                    <div className="col-md-6 row">
                                      <div className="info-item-title col-4">
                                        Last Name
                                      </div>
                                      <div className="info-item-data col-8">
                                        {department?.lastNameContact}
                                      </div>
                                    </div>
                                    <div className="col-md-6 row">
                                      <div className="info-item-title col-4">
                                        Job Title
                                      </div>
                                      <div className="info-item-data col-8">
                                        {department?.jobTitleContact}
                                      </div>
                                    </div>
                                    <div className="col-md-6 row">
                                      <div className="info-item-title col-4">
                                        Email Address
                                      </div>
                                      <div className="info-item-data col-8">
                                        {department?.emailContact}
                                      </div>
                                    </div>
                                    <div className="col-md-6 row">
                                      <div className="info-item-title col-4">
                                        Mobile Number
                                      </div>
                                      <div className="info-item-data col-8">
                                        {department?.phoneNumberContact}
                                      </div>
                                    </div>
                                    <div className="col-md-6 row">
                                      <div className="info-item-title col-4">
                                        Work Phone Number
                                      </div>
                                      <div className="info-item-data col-8">
                                        {department?.workPhoneNumberContact}
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-md-6 row">
                                    <div className="info-item-title col-4">
                                      Department
                                    </div>
                                    <div className="info-item-data col-8">
                                      {department?.departmentContact}
                                    </div>
                                  </div>
                                </div>
                                <div className="info-card-content row mb-5">
                                  <div className="col-md-12">
                                    <TitleCard>Account</TitleCard>
                                  </div>
                                  <div className="info-card-content row">
                                    <div className="col-md-6 row">
                                      <div className="info-item-title col-4">
                                        Title
                                      </div>
                                      <div className="info-item-data col-8">
                                        {department?.titleAccount}
                                      </div>
                                    </div>
                                    <div className="col-md-6 row">
                                      <div className="info-item-title col-4">
                                        First Name
                                      </div>
                                      <div className="info-item-data col-8">
                                        {department?.firstNameAccount}
                                      </div>
                                    </div>
                                    <div className="col-md-6 row">
                                      <div className="info-item-title col-4">
                                        Last Name
                                      </div>
                                      <div className="info-item-data col-8">
                                        {department?.lastNameAccount}
                                      </div>
                                    </div>
                                    <div className="col-md-6 row">
                                      <div className="info-item-title col-4">
                                        Job Title
                                      </div>
                                      <div className="info-item-data col-8">
                                        {department?.jobTitleAccount}
                                      </div>
                                    </div>
                                    <div className="col-md-6 row">
                                      <div className="info-item-title col-4">
                                        Email Address
                                      </div>
                                      <div className="info-item-data col-8">
                                        {department?.emailAccount}
                                      </div>
                                    </div>
                                    <div className="col-md-6 row">
                                      <div className="info-item-title col-4">
                                        Mobile Number
                                      </div>
                                      <div className="info-item-data col-8">
                                        {department?.phoneNumberAccount}
                                      </div>
                                    </div>
                                    <div className="col-md-6 row">
                                      <div className="info-item-title col-4">
                                        Work Phone Number
                                      </div>
                                      <div className="info-item-data col-8">
                                        {department?.workPhoneNumberAccount}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="info-card-content row mb-5">
                                  <div className="col-md-12">
                                    <TitleCard>Bank details</TitleCard>
                                  </div>
                                  <div className="col-md-6 row">
                                    <div className="info-item-title col-4">
                                      Bank Name
                                    </div>
                                    <div className="info-item-data col-8">
                                      {department?.bank?.name}
                                    </div>
                                  </div>
                                  <div className="col-md-6 row">
                                    <div className="info-item-title col-4">
                                      Account Name
                                    </div>
                                    <div className="info-item-data col-8">
                                      {department?.bank?.accountName}
                                    </div>
                                  </div>
                                  <div className="col-md-6 row">
                                    <div className="info-item-title col-4">
                                      Account Number
                                    </div>
                                    <div className="info-item-data col-8">
                                      {department?.bank?.accountNumber}
                                    </div>
                                  </div>
                                  <div className="col-md-6 row">
                                    <div className="info-item-title col-4">
                                      Type
                                    </div>
                                    <div className="info-item-data col-8">
                                      -{' '}
                                    </div>
                                  </div>
                                  <div className="col-md-6 row">
                                    <div className="info-item-title col-4">
                                      Sort Code
                                    </div>
                                    <div className="info-item-data col-8">
                                      {department?.bank?.sortNumber}
                                    </div>
                                  </div>
                                </div>
                                <div className="info-card-content mb-5 d-flex justify-content-end">
                                  <Button
                                    type="danger"
                                    onClick={() =>
                                      onClickDeleteDepartment(
                                        (department as any)._id,
                                      )
                                    }
                                  >
                                    <DeleteIcon /> Delete Department
                                  </Button>
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                      {!client.departments.length && (
                        <div className="text-center m-4">
                          No departments added. Please try to add
                        </div>
                      )}
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </CardContainer>
      </ClientDetailsContainer>
      <SideModal
        isOpen={isModalOpen}
        setIsOpen={setIsModalOpen}
        title="New Department"
      >
        <NewDepartmentForm
          getClientDetails={getClientDetails}
          setIsOpen={setIsModalOpen}
        />
      </SideModal>
    </>
  );
};
