import React from 'react';
import { Badge, Table } from '../../ui';
import {
  AxiosInstance,
  AxiosInstanceErrorResponse,
  formatDate,
} from '../../../utils';
import { DeleteIcon } from '../../../icons';
import { toast } from 'react-toastify';
import { useParams } from 'react-router-dom';
import {
  EAvailabilityStatus,
  EAvailabilityTime,
} from '../../../backend/careo-api';

type AvailabilityListProps = {
  filteredAvailabilities: {
    _id: string;
    from: string;
    to: string | undefined;
    availabilityTimeLabel: string;
    differenceDays: string;
    status: EAvailabilityStatus;
  }[];
  getAvailabilities: any;
};

const AvailabilityList = ({
  filteredAvailabilities,
  getAvailabilities,
}: AvailabilityListProps) => {
  const { id } = useParams();

  const onAvailabilityClickDelete = async (from: string, to?: string) => {
    await AxiosInstance.availability
      .availabilityControllerDeleteAvailabilityByRangeDate({
        candidateId: id!,
        from: from,
        to: to ? to : undefined,
      })
      .then(() => {
        toast.success('Availability Removed successfully');
        getAvailabilities();
      })
      .catch((error: AxiosInstanceErrorResponse) => {
        toast.error(error.message);
      });
  };

  return (
    <Table>
      <thead>
        <tr>
          <th>From</th>
          <th>To</th>
          <th>Total</th>
          <th>Availability</th>
          <th>Status</th>
          <th className="action-th">Action</th>
        </tr>
      </thead>
      <tbody>
        {filteredAvailabilities.map((el) => {
          return (
            <tr key={el._id}>
              <td>{formatDate(el.from)}</td>
              <td>{el.to ? formatDate(el.to) : '-'}</td>
              <td>{el.differenceDays}</td>
              <td>{el.availabilityTimeLabel}</td>
              <td>
                <Badge
                  text={el.status}
                  type={
                    el.status === EAvailabilityStatus.AVAILABLE
                      ? 'success'
                      : 'warning'
                  }
                />
              </td>
              <td>
                <div className="action-item">
                  {el.status === EAvailabilityStatus.AVAILABLE}
                  <div
                    className={`delete-icon ${
                      el.status !== EAvailabilityStatus.AVAILABLE && 'disabled'
                    }`}
                    onClick={() =>
                      el.status === EAvailabilityStatus.AVAILABLE &&
                      onAvailabilityClickDelete(el.from, el.to)
                    }
                  >
                    <DeleteIcon />
                  </div>
                </div>
              </td>
            </tr>
          );
        })}
      </tbody>
    </Table>
  );
};

export default AvailabilityList;
