import { Navigate } from 'react-router-dom';
import { isCRMApp } from '../environment/app.type';
import { CandidatesListPage, CandidateDetailsPage } from '../pages/candidate';
import {
  SharedCandidatesDetailsPage,
  SharedCandidatesListPage,
} from '../pages/shared-candidates';
import { IRoute } from '../types/routes.type';
import { PagesUrls } from './page-urls';
import HomeCompliancePage from '../pages/home-compliance/home-compliance.page';
import { CandidateComplianceDetailsPage } from '../pages/home-compliance/candidate-compliance-details.page';

export const CandidatesRoutesCRM: IRoute[] = [
  {
    titleKey: PagesUrls.CANDIDATES.Index,
    path: PagesUrls.CANDIDATES.Index,
    element: <CandidatesListPage />,
    isVisibleOnSidebar: true,
  },
  {
    path: PagesUrls.CANDIDATES.Details,
    element: <CandidateDetailsPage />,
  },
  {
    titleKey: PagesUrls.SHARED_CANDIDATES.Index,
    path: PagesUrls.SHARED_CANDIDATES.Index,
    element: <SharedCandidatesListPage />,
  },
  {
    path: PagesUrls.SHARED_CANDIDATES.Details,
    element: <SharedCandidatesDetailsPage />,
  },
];

export const CandidatesRoutesCompliance: IRoute[] = [
  {
    titleKey: PagesUrls.CANDIDATES.Index,
    path: PagesUrls.CANDIDATES_COMPLIANCE.Index,
    element: <HomeCompliancePage />,
    isVisibleOnSidebar: true,
  },
  {
    titleKey: PagesUrls.SHARED_CANDIDATES.Index,
    path: PagesUrls.SHARED_CANDIDATES.Index,
    element: <SharedCandidatesListPage />,
  },
  {
    path: PagesUrls.SHARED_CANDIDATES.Details,
    element: <SharedCandidatesDetailsPage />,
  },
  {
    path: PagesUrls.CANDIDATES_COMPLIANCE.Details,
    element: <CandidateComplianceDetailsPage />,
  },
  {
    path: PagesUrls.CANDIDATES_COMPLIANCE.Documents,
    element: <CandidateComplianceDetailsPage />,
  },
  {
    path: PagesUrls.CANDIDATES_COMPLIANCE.Compliance,
    element: <CandidateComplianceDetailsPage />,
  },
  {
    path: PagesUrls.CANDIDATES_COMPLIANCE.ComplianceDetails,
    element: <CandidateComplianceDetailsPage />,
  },
];
