import React, { useEffect, useMemo, useState } from 'react';
import { Candidate } from '../../../backend/careo-api';
import { SortIcon, LeftArrowIcon, RightArrowIcon } from '../../../icons';
import {
  TFilterCandidate,
  filterCandidates,
  getItemsOfPage,
  getNumberOfPages,
  onSelectSort,
  sortCandidates,
} from '../../../utils';
import { SearchInput, Table, Checkbox, UserIcon, Badge } from '../../ui';
import { getStatus } from '../../../utils/status.utils';

const numberOfItems = 5;

type FilterCandidatesProps = {
  candidates: Candidate[];
  selectedCandidatesIds: string[];
  setSelectedCandidatesIds: React.Dispatch<React.SetStateAction<string[]>>;
};

const FilterCandidates = ({
  candidates,
  selectedCandidatesIds,
  setSelectedCandidatesIds,
}: FilterCandidatesProps) => {
  const [candidatesList, setCandidatesList] = useState<Candidate[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);

  const [filter, setFilter] = useState<TFilterCandidate>({
    search: '',
    regions: [],
    counties: [],
    grades: [],
    levels: [],
    status: [],
    completions: [],
  });
  const [sort, setSort] = useState<{ key: string; value: '+' | '-' | '' }>({
    key: '',
    value: '',
  });

  const navigatePagination = (newPage: number) => {
    if (newPage <= 0 || newPage > totalPages) {
      return;
    }
    setCurrentPage(newPage);
  };

  const onClickCheckboxAll = (value: boolean) => {
    setSelectedCandidatesIds((prev) => {
      if (value) {
        return [...candidatesList.map((el) => el._id)];
      } else return [];
    });
  };

  const onClickCheckbox = (id: string, value: boolean) => {
    setSelectedCandidatesIds((prev) => {
      if (value) {
        prev.push(id);
      } else {
        prev = prev.filter((el) => el !== id);
      }
      return [...prev];
    });
  };

  const paginatedData = useMemo(() => {
    return getItemsOfPage(candidatesList, currentPage, numberOfItems);
  }, [candidatesList, currentPage]);

  useEffect(() => {
    const filteredData = filterCandidates(candidates, filter);
    const sortedData = sortCandidates(filteredData, sort);
    setCandidatesList(sortedData);
    setCurrentPage(1);
    setTotalPages(getNumberOfPages(sortedData.length, numberOfItems));
  }, [candidates, filter, sort]);

  return (
    <>
      <div className="filter-container">
        <SearchInput
          placeholder="Search candidate"
          onChange={(e) =>
            setFilter((prev) => ({ ...prev, search: e.target.value ?? '' }))
          }
        />
      </div>
      <div className="data-table-container">
        <Table>
          <thead>
            <tr>
              <th className="checkbox-table">
                <Checkbox
                  checked={
                    !!selectedCandidatesIds.length &&
                    selectedCandidatesIds.length === candidatesList.length
                  }
                  indeterminate={
                    !!selectedCandidatesIds.length &&
                    selectedCandidatesIds.length < candidatesList.length
                  }
                  onChange={(e) => onClickCheckboxAll(e.target.checked)}
                />
              </th>
              <th onClick={() => onSelectSort('firstName', setSort)}>
                <div>
                  <label>Name </label>
                  <SortIcon
                    value={sort.key === 'firstName' ? sort.value : ''}
                  />
                </div>
              </th>
              <th onClick={() => onSelectSort('designation', setSort)}>
                <div>
                  <label>Designation</label>
                  <SortIcon
                    value={sort.key === 'designation' ? sort.value : ''}
                  />
                </div>
              </th>
              <th onClick={() => onSelectSort('grade', setSort)}>
                <div>
                  <label>NHS Pay Grade</label>
                  <SortIcon value={sort.key === 'grade' ? sort.value : ''} />
                </div>
              </th>
              <th onClick={() => onSelectSort('county', setSort)}>
                <div>
                  <label>County</label>
                  <SortIcon value={sort.key === 'county' ? sort.value : ''} />
                </div>
              </th>
              <th onClick={() => onSelectSort('region', setSort)}>
                <div>
                  <label>Region</label>
                  <SortIcon value={sort.key === 'region' ? sort.value : ''} />
                </div>
              </th>
              <th onClick={() => onSelectSort('status', setSort)}>
                <div>
                  <label>Status</label>
                  <SortIcon value={sort.key === 'status' ? sort.value : ''} />
                </div>
              </th>
              <th>
                <div></div>
              </th>
            </tr>
          </thead>
          <tbody>
            {paginatedData.map((candidate) => (
              <tr key={candidate._id}>
                <td className="checkbox-table">
                  <input
                    type="checkbox"
                    onChange={(e) =>
                      onClickCheckbox(candidate._id, e.target.checked)
                    }
                    checked={selectedCandidatesIds.some(
                      (el) => el === candidate._id,
                    )}
                  />
                </td>
                <td>
                  <div className="name-item">
                    <UserIcon
                      firstName={candidate.firstName}
                      lastName={candidate.lastName}
                      entity="candidate"
                    />
                    <div>
                      <div>
                        {candidate.firstName} {candidate.lastName}
                      </div>
                      <div className="email">{candidate.email}</div>
                    </div>
                  </div>
                </td>
                <td>
                  <div className="name-item">
                    <div>
                      <div> {candidate?.designation || '-'}</div>
                      <div className="email">{candidate.department || '-'}</div>
                    </div>
                  </div>
                </td>
                <td>{candidate?.grade}</td>
                <td>{candidate.address?.county || '-'} </td>
                <td>{candidate.address?.region || '-'} </td>
                <td>
                  <Badge text={'Active'} type={getStatus('Active')} />
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
      <div className="pagination-container">
        <div className="navigation-container">
          <div
            className={`left-arrow ${currentPage === 1 && 'disabled'}`}
            onClick={() => navigatePagination(currentPage - 1)}
          >
            <LeftArrowIcon />
          </div>
          <div className="pages-list">
            {Array.from({ length: totalPages }, (_, index) => index + 1).map(
              (el) => (
                <div
                  className={`page-number ${el === currentPage && 'active'}`}
                  onClick={() => navigatePagination(el)}
                >
                  {el}
                </div>
              ),
            )}
          </div>
          <div
            className={`right-arrow ${
              currentPage === totalPages && 'disabled'
            }`}
            onClick={() => navigatePagination(currentPage + 1)}
          >
            <RightArrowIcon />
          </div>
        </div>
        <div className="information-container">
          Showing {currentPage} to {totalPages} of {candidatesList.length}{' '}
          entries
        </div>
      </div>
    </>
  );
};

export default FilterCandidates;
