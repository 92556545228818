import React, { useEffect, useState } from 'react';
import { Button, CardContainer, Table, TitlePage } from '../ui';
import { DeleteIcon, EditIcon, PlusIcon, SortIcon } from '../../icons';
import { Campaign, CampaignTemplate } from '../../backend/careo-api';
import { AxiosInstance, AxiosInstanceErrorResponse } from '../../utils';
import { toast } from 'react-toastify';
import { Switch } from '@mui/material';
import { NewCampaignForm, UpdateCampaignForm } from '../campaign';
import { SideModal } from '../modals';
import { NewCampaignTemplateForm } from '../campaign/new-campaign-template-form/new-campaign-template-form.component';
import { UpdateCampaignTemplateForm } from '../campaign/update-campaign-template-form/update-campaign-template-form.component';

type CampaignTemplatesProps = {};

export const CampaignTemplates = ({}: CampaignTemplatesProps) => {
  const [campaignTemplatesList, setCampaignTemplates] = useState<
    CampaignTemplate[]
  >([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [entityToUpdate, setEntityToUpdate] = useState<CampaignTemplate | null>(
    null,
  );

  const onClickDeleteCampaign = async (id: string) => {
    await AxiosInstance.campaignTemplates
      .campaignTemplatesControllerDelete(id)
      .then((response) => {
        toast.success('Campaign Template deleted successfully');
        getCampaignTemplates();
      })
      .catch((error: AxiosInstanceErrorResponse) => {
        toast.error(error.message);
      });
  };

  const getCampaignTemplates = async () => {
    await AxiosInstance.campaignTemplates
      .campaignTemplatesControllerFindAll()
      .then((response) => {
        setCampaignTemplates(response.data as unknown as CampaignTemplate[]);
      })
      .catch((error: AxiosInstanceErrorResponse) => {
        toast.error(error.message);
      });
  };

  useEffect(() => {
    getCampaignTemplates();
  }, []);

  return (
    <>
      <CardContainer className="right-container">
        <div className="settings-title-content">
          <TitlePage>Campaign Templates</TitlePage>
          <Button type="primary" onClick={() => setIsModalOpen(true)}>
            <PlusIcon />
            Add New
          </Button>
        </div>
        <hr />
        <div className="settings-content">
          <div className="data-table-container">
            <Table>
              <thead>
                <tr>
                  <th>
                    <div>
                      <label>Template Name</label>
                      <SortIcon />
                    </div>
                  </th>
                  <th>
                    <div>
                      <label>Message</label>
                      <SortIcon />
                    </div>
                  </th>
                  <th className="action-th">
                    <div>Action</div>
                  </th>
                </tr>
              </thead>
              <tbody>
                {campaignTemplatesList.map((campaign) => (
                  <tr key={campaign._id}>
                    <td>{campaign.name}</td>
                    <td>{campaign.message}</td>
                    <td>
                      <div className="action-item">
                        <div
                          className="edit-icon"
                          onClick={() => setEntityToUpdate(campaign)}
                        >
                          <EditIcon />
                        </div>
                        <div
                          className="delete-icon"
                          onClick={() => onClickDeleteCampaign(campaign._id)}
                        >
                          <DeleteIcon />
                        </div>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        </div>
      </CardContainer>

      <SideModal
        isOpen={isModalOpen}
        setIsOpen={setIsModalOpen}
        title={'New Template'}
      >
        <NewCampaignTemplateForm
          setIsOpen={setIsModalOpen}
          getCampaignTemplates={getCampaignTemplates}
        />
      </SideModal>

      <SideModal
        isOpen={!!entityToUpdate}
        setIsOpen={() => setEntityToUpdate(null)}
        title={'Update Template'}
      >
        <UpdateCampaignTemplateForm
          campaignTemplate={entityToUpdate as CampaignTemplate}
          setIsOpen={() => setEntityToUpdate(null)}
          getCampaignTemplates={getCampaignTemplates}
        />
      </SideModal>
    </>
  );
};
