import React, { Dispatch, SetStateAction } from 'react';
import { Select, Button } from '../../ui';
import { useForm } from 'react-hook-form';
import {
  Application,
  Candidate,
  EApplicationStatus,
  Job,
} from '../../../backend/careo-api';
import { NewPlacementFormContainer } from './new-placement-form.style';
import { AxiosInstance } from '../../../utils';
import { toast } from 'react-toastify';
import { yupResolver } from '@hookform/resolvers/yup';
import { PlacementCreateByApplicationIdSchema } from '../../../utils/validators/playcement.validator';

type NewPlacementFormProps = {
  applicationsList: Application[];
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  getPlacements: any;
};

export const NewPlacementForm = ({
  applicationsList,
  setIsOpen,
  getPlacements,
}: NewPlacementFormProps) => {
  const {
    getValues,
    handleSubmit,
    formState: { errors },
    register,
  } = useForm<{ applicationId: string }>({
    resolver: yupResolver(PlacementCreateByApplicationIdSchema as any),
  });

  const onClickClose = () => {
    setIsOpen(false);
  };

  const onClickSubmit = () => {
    const values = getValues();
    AxiosInstance.applications
      .applicationsControllerUpdateApplicationStatus(
        values.applicationId as any,
        {
          status: EApplicationStatus.Placement,
        },
      )
      .then(() => {
        getPlacements();
        toast.success('Placement added successfully');
        onClickClose();
      });
  };

  return (
    <NewPlacementFormContainer>
      <Select
        placeholder="Select Application"
        label="Application"
        options={[
          ...applicationsList.map((el) => ({
            label:
              (el.job as Job)?.grade +
              ' / ' +
              (el.job as Job)?.specialty +
              ' : ' +
              (el.candidate as Candidate)?.firstName +
              ' ' +
              (el.candidate as Candidate)?.lastName,
            value: el._id,
          })),
        ]}
        register={register('applicationId')}
        required
        error={errors.applicationId}
      />
      <div className="form-actions">
        <Button onClick={onClickClose}>Cancel</Button>
        <Button type="primary" onClick={handleSubmit(onClickSubmit)}>
          Create
        </Button>
      </div>
    </NewPlacementFormContainer>
  );
};
