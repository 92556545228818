import styled from 'styled-components';
import { breakpoint } from '../../../constants';

export const EmailContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: 10px;
  overflow-y: auto;
  position: relative;
  .email-item-container {
    gap: 10px;
    display: flex;
    flex-direction: column;
    background-color: white;
    border-radius: 10px;

    .header-conversation {
      padding: 24px;
      border-bottom: 1px solid #f1f2f4;
      gap: 16px;
      display: flex;
      flex-direction: column;
      .subject-header {
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
      }
      .details-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 16px;
        .left-header {
          display: flex;
          align-items: center;
          gap: 16px;
          .sender-profile-image {
            width: 40px;
            height: 40px;
          }
          .sender-info-messenger {
            .sender-name {
              cursor: pointer;
              font-size: 14px;
              font-weight: 600;
              color: #111827 !important;
            }
            .receiver-name {
              cursor: pointer;
              font-size: 10px;
              font-weight: 400;
              color: #a0aec0 !important;
            }
          }
        }
        .right-header {
          display: flex;
          gap: 5px;
          .time-receiving {
            width: fit-content;
            color: #a0aec0;
            font-size: 12px;
            font-weight: 400;
            display: flex;
            align-items: center;
          }
          > div:not(.time-receiving) {
            cursor: pointer;
            border-radius: 100px;
            width: 30px;
            height: 30px;
            display: flex;
            align-items: center;
            justify-content: center;
            &:hover {
              box-shadow: rgba(17, 17, 26, 0.1) 0px 1px 0px,
                rgba(17, 17, 26, 0.1) 0px 8px 24px,
                rgba(17, 17, 26, 0.1) 0px 16px 48px;
            }
          }
          .arrows {
            svg {
              width: 17px;
              path {
                stroke: #111827;
              }
            }
          }
          .icons {
            svg {
              width: 20px;
              path {
                fill: #111827;
              }
            }
          }
        }
      }
    }

    .content-conversation {
      padding: 24px;
      display: flex;
      flex-direction: column;
      gap: 10px;
      flex: 1;
      overflow-y: auto;
      .email-item {
        display: flex;
        flex-direction: column;
        .email-title {
          font-size: 14px;
          font-weight: 500;
        }
        .email-attachment-file-container {
          display: flex;
          align-items: center;
          gap: 5px;
          flex-wrap: wrap;
          .attachment-file {
            display: flex;
            align-items: center;
            border: 1px solid #525252;
            padding: 3px 7px;
            border-radius: 10px;
            color: #111827;
            font-size: 14px;
            gap: 5px;
            cursor: pointer;
            svg {
              fill: #111827;
            }
            &:hover {
              box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px inset,
                rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset;
            }
          }
        }
        .email-content {
          font-size: 13px;
          margin: 16px 0 30px 0;
          white-space: break-spaces;
        }
      }

      .email-form {
        display: flex;
        flex-direction: column;
        border-radius: 16px;
        border: 1px solid #f1f2f4;
        background: #fff;
        .email-to {
          padding: 16px 24px;
          font-size: 12px;
          display: flex;
          align-items: center;
          border-bottom: 1px solid #f1f2f4;
          .email-profile {
            display: flex;
            padding: 4px 10px 4px 4px;
            align-items: center;
            gap: 10px;
            border-radius: 100px;
            background: #fafafa;
          }
        }
        .email-attachments {
          padding: 16px 24px;
          font-size: 12px;
          display: flex;
          align-items: center;
          border-bottom: 1px solid #f1f2f4;
          svg {
            path {
              fill: #111827;
            }
            cursor: pointer;
          }
          .attachments-list {
            display: flex;
            align-items: center;
            gap: 5px;
            .attachment-file {
              display: flex;
              align-items: center;
              gap: 10px;
              svg {
                width: 15px;
                height: 15px;
                cursor: pointer;
                border-radius: 50%;
                &:hover {
                  background-color: rgba(149, 157, 165, 0.2);
                }
              }
              border: 1px solid #525252;
              padding: 2px 5px;
              border-radius: 10px;
            }
          }
        }
        .email-title {
          padding: 16px 24px;
          border-bottom: 1px solid #f1f2f4;
          font-size: 12px;
          input {
            border: unset;
            outline: unset;
          }
        }
        .email-content {
          padding: 16px 24px;
          font-size: 12px;
          textarea {
            border: unset;
            outline: unset;
            width: 100%;
            resize: none;
          }
        }
      }

      .actions-container {
        display: flex;
        gap: 10px;
        align-items: center;
        .email-actions {
          display: inline-flex;
          height: 32px;
          padding: 21px 16px;
          justify-content: center;
          align-items: center;
          gap: 8px;
          flex-shrink: 0;
          border-radius: 8px;
          border: 1px solid #9d9ca3;
          color: #9d9ca3;
          svg {
            path {
              fill: unset;
              stroke: #9d9ca3;
            }
          }
        }
      }
    }

    .input-area {
      display: flex;
      padding: 16px 24px;
      align-items: center;
      border-top: 1px solid #f1f2f4;
      gap: 10px;

      .attach-button {
        cursor: pointer;
        button {
          width: 100px;
          display: -webkit-box;
          -webkit-line-clamp: 3;
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        &:hover {
          box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.1);
        }
        svg {
          width: 24px;
          height: 24px;
          path {
            fill: #111827;
          }
        }
      }
      .input-container {
        flex: 1;
        textarea {
          resize: none;
          width: 100%;
          padding: 5px 10px;
          border: 1px solid #f1f2f4;
          font-size: 14px;
          border-radius: 10px;
          outline: none;
        }
      }
      .send-button {
        display: flex;
        width: 50px;
        height: 50px;
        justify-content: center;
        align-items: center;
        background-color: #27a376;
        border-radius: 100px;
        cursor: pointer;
        &:hover {
          box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.1);
        }
        svg {
          path {
            fill: white;
          }
        }
      }
    }
  }
  .footer-email {
    position: fixed;
    bottom: 40px;
    right: 40px;
  }

  .no-conversation {
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background-color: white;
    border-radius: 16px;
  }

  .create-email-container {
    display: flex;
    flex-direction: column;
    background-color: white;
    border-radius: 10px;
    .ql-toolbar.ql-snow,
    .subject-input,
    .to-input {
      padding: 16px 24px;
      display: flex;
      align-items: center;
      gap: 5px;

      > div {
        width: 100%;
      }

      border-bottom: 1px solid #f1f2f4 !important;
      .input-container input {
        padding: 5px;
        border: unset;
        outline: unset;
      }
    }

    .email-attachments {
      padding: 16px 24px;
      font-size: 12px;
      display: flex;
      align-items: center;
      border-bottom: 1px solid #f1f2f4;
      overflow: auto;
      svg {
        path {
          fill: #111827;
        }
        cursor: pointer;
      }
      .attachments-list {
        display: flex;
        align-items: center;
        gap: 5px;
        overflow-x: scroll;
        .attachment-file {
          display: flex;
          align-items: center;
          gap: 10px;
          white-space: nowrap;
          svg {
            width: 15px;
            height: 15px;
            cursor: pointer;
            border-radius: 50%;
            &:hover {
              background-color: rgba(149, 157, 165, 0.2);
            }
          }
          border: 1px solid #525252;
          padding: 2px 5px;
          border-radius: 10px;
        }
      }
    }

    .subject-input {
    }
    .ql-snow .ql-picker-label {
      padding-left: unset;
    }
    .ql-toolbar.ql-snow,
    .ql-container.ql-snow {
      border: unset;
    }

    .ql-editor {
      height: 300px;
    }
  }

  .actions-buttons {
    z-index: 10000;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 10px;
    .cancel-button {
      svg {
        path {
          stroke: #ffedec;
        }
      }
    }
  }

  @media (max-width: ${breakpoint.mobile}px) {
    .header-conversation {
      padding: 10px;
      gap: 5px;
      .profile-image {
        width: 40px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .info-messenger {
        .name {
          font-size: 12px;
        }
        .status {
          font-size: 8px;
        }
      }
    }

    .content-conversation {
      padding: 10px;
      gap: 5px;
      .email-item {
        padding: 10px;
        .email-title {
          font-size: 10px;
        }
        .email-content {
          font-size: 9px;
        }
        .email-time {
          font-size: 8px;
        }
      }

      .email-form {
        .email-to {
          padding: 5px 8px;
          font-size: 8px;
          .email-profile {
            padding: 4px 10px 4px 4px;
            gap: 5px;
          }
        }
        .email-attachments {
          padding: 5px 8px;
          font-size: 8px;
          .attachments-list {
            gap: 5px;
            .attachment-file {
              gap: 5px;
            }
          }
        }
        .email-title {
          padding: 5px 8px;
          font-size: 8px;
        }
        .email-content {
          padding: 5px 8px;
          font-size: 8px;
        }
      }
    }

    .input-area {
      padding: 5px 8px;
      gap: 5px;
      .attach-button {
        svg {
          width: 15px;
          height: 15px;
        }
      }
      .input-container {
        flex: 1;
        textarea {
          font-size: 10px;
        }
      }
      .send-button {
        width: 25px;
        height: 25px;
      }
    }

    .emails-container {
    }

    .footer-email {
      padding: 16px 24px;
      display: flex;
      justify-content: flex-end;
      gap: 20px;
    }

    .no-conversation {
      display: flex;
      height: 100%;
      align-items: center;
      justify-content: center;
    }
  }
`;
