import React, { useMemo, useState } from 'react';
import { Table } from '../ui';
import { DeleteIcon, DownloadIcon, EditIcon, SortIcon } from '../../icons';
import {
  TSortDocument,
  TSortValues,
  formatDateTime,
  onSelectSort,
  sortDocuments,
} from '../../utils';
import { SideModal } from '../modals';
import { RenameDocumentForm } from './rename-document-form.component';
import { downloadFile } from '../../utils/utils';
import { HostedFile } from '../../backend/careo-api';
import { ConfirmModal } from '../modals/confirm-modal/confirm-modal.component';

type DocumentsListComponentProps = {
  documents: HostedFile[];
  onClickDelete: (id: string) => void;
  getDocuments: () => void;
  onDocumentRename?: (_id: string, newFileName: string) => void;
};

export const DocumentsListComponent = ({
  documents,
  onClickDelete,
  getDocuments,
  onDocumentRename,
}: DocumentsListComponentProps) => {
  const [isModalOpen, setIsModalOpen] = useState<HostedFile>();
  const [documentIdToBeDelete, setDocumentIdToBeDelete] =
    useState<HostedFile>();

  const [sort, setSort] = useState<{
    key: TSortDocument;
    value: TSortValues;
  }>({
    key: '',
    value: '',
  });

  const data = useMemo(() => {
    return sortDocuments(documents, sort);
  }, [documents, sort]);

  const onClickEdit = (document: HostedFile) => {
    setIsModalOpen(document);
  };

  return (
    <>
      <Table>
        <thead>
          <tr>
            <th onClick={() => onSelectSort('fileName', setSort)}>
              <div>
                <label>Document Name</label>
                <SortIcon value={sort.key === 'fileName' ? sort.value : ''} />
              </div>
            </th>
            <th onClick={() => onSelectSort('timestamp', setSort)}>
              <div>
                <label>Date</label>
                <SortIcon value={sort.key === 'timestamp' ? sort.value : ''} />
              </div>
            </th>
            <th className="action-th">Action</th>
          </tr>
        </thead>
        <tbody>
          {data.map((document) => {
            if (document)
              return (
                <tr key={document._id}>
                  <td>{document.fileName}</td>
                  <td>{formatDateTime(document.timestamp)}</td>
                  <td>
                    <div className="action-item">
                      <div
                        className="download-icon"
                        onClick={() => downloadFile(document)}
                      >
                        <DownloadIcon />
                      </div>
                      <div
                        className="edit-icon"
                        onClick={() => onClickEdit(document)}
                      >
                        <EditIcon />
                      </div>
                      <div
                        className="delete-icon"
                        onClick={() => setDocumentIdToBeDelete(document)}
                      >
                        <DeleteIcon />
                      </div>
                    </div>
                  </td>
                </tr>
              );
          })}
        </tbody>
      </Table>
      <SideModal
        isOpen={!!isModalOpen}
        setIsOpen={() => setIsModalOpen(undefined)}
        title={'Rename document'}
      >
        <RenameDocumentForm
          onCancel={() => setIsModalOpen(undefined)}
          onSuccess={(_id, newFileName) => {
            getDocuments();
            setIsModalOpen(undefined);
            onDocumentRename?.(_id, newFileName);
          }}
          document={isModalOpen!}
        />
      </SideModal>

      {documentIdToBeDelete && (
        <ConfirmModal
          isOpen={true}
          title="Delete File"
          onNegativeBtnClick={() => setDocumentIdToBeDelete(undefined)}
          onPositiveBtnClick={() => {
            onClickDelete(documentIdToBeDelete?._id!);
            setDocumentIdToBeDelete(undefined);
          }}
        >
          Do you want to delete <b>{documentIdToBeDelete?.fileName}</b>
        </ConfirmModal>
      )}
    </>
  );
};
