import { LoginPage } from '../pages/auth/login/login.page';
import { IRoute } from '../types/routes.type';
import { PagesUrls } from './page-urls';

export const AuthRoutes: IRoute[] = [
  {
    titleKey: PagesUrls.AUTHENTICATION.Login,
    path: PagesUrls.AUTHENTICATION.Login,
    withoutLayout: true,
    element: <LoginPage />,
  },
];
